import {
  AssetSourceType,
  AssetValueType,
  type DallEOutputType,
  type TDallEAsset
} from "@toolflow/shared";
import { v4 as uuidv4 } from "uuid";

export const getNewDallEAsset = ({
  value,
  id,
  name,
  version = 1,
  toolId,
  sourceIdentifier
}: {
  value?: DallEOutputType;
  id?: string;
  name?: string;
  version?: number;
  toolId?: string;
  sourceIdentifier?: string;
}): TDallEAsset => {
  return {
    label: name || `New image asset`,
    name: name || `New image asset`,
    source: toolId ? AssetSourceType.ToolOutput : AssetSourceType.Manual,
    value: value || [],
    valueType: AssetValueType.DallE,
    id: id || uuidv4(),
    sourceIdentifier,
    data: {
      version,
      toolId
    }
  };
};
