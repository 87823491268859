import { CssBaseline, useTheme } from "@mui/material";
import useExtension from "./hooks/useExtensions/useExtension";
import type { Content } from "@tiptap/core";
import { RichTextReadOnly } from "mui-tiptap";

import { ThemeProvider } from "@emotion/react";

export const GenericRichContent = ({
  content,
  className = ""
}: {
  content: Content;
  className?: string;
}) => {
  const extensions = useExtension({ enableAutolinkExtension: true });

  // i don't like doing this, but i've added tiptap
  // styles to app.css with !important to space out the nodes a bit
  // having trouble with precedence of themes right now and
  // styling the mui-tiptap
  const theme = useTheme();

  if (!content) return null;

  return (
    <div className={className}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RichTextReadOnly
          autofocus={"all"}
          content={content}
          extensions={extensions}
          editorProps={{
            handleDOMEvents: {
              keydown: (view, event) => {
                if ((event.ctrlKey || event.metaKey) && event.key === "c") {
                } else {
                  event.preventDefault();
                }
                return true;
              }
            }
          }}
        />
      </ThemeProvider>
    </div>
  );
};
