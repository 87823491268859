import TipTapTextField from "../../../../../../../utilities/components/textFields/tipTapTextField/TipTapTextField";
import AuthenticationSplitter from "../../../../../../auth/AuthenticationSplitter";
import LoggedOutCTA from "../../../../../external/LoggedOutCTA";
import useSendThreadTextField from "./hooks/useSendThreadTextField";
import styles from "./sendThreadTextField.module.css";
import { UnableToRunAgentCTA } from "./UnableToRunAgentCTA";

function SendThreadTextFieldInner() {
  const {
    userTextMessage,
    handleChange,
    handleKeyDown,
    additionalActions,
    chipsToUse,
    agentId,
    placeholderText
  } = useSendThreadTextField();

  return (
    <>
      <TipTapTextField
        key={agentId} // we need to rerender the placeholder text when the agent changes
        initialValue={userTextMessage}
        richTextFieldClassName="p-0px-imp"
        label={placeholderText}
        onChange={handleChange}
        outerClassName={styles.outline}
        autoFocus={true}
        enableHover={false}
        variant="standard"
        disableOutlineHover
        saveWorkspaceType="json"
        disableFormattingMenu
        extensionsConfig={{
          disableDefaultEnter: true
        }}
        overrideKeydown={handleKeyDown}
        additionalActions={additionalActions}
        chipsToUse={chipsToUse}
      />
      <UnableToRunAgentCTA />
    </>
  );
}

function SendThreadTextField() {
  return (
    <AuthenticationSplitter
      authenticatedComponent={<SendThreadTextFieldInner />}
      externalComponent={<LoggedOutCTA />}
    />
  );
}

export default SendThreadTextField;
