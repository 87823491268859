import { type FileReference } from "@toolflow/shared";
import SelectedFile from "./SelectedFile";
import NoFileSelectedPaper from "./NoSelectedFilePaper";
import { type Accept } from "react-dropzone";

interface FileUploadButtonInnerProps {
  accept?: Accept;
  maxSize?: number;
  disabled?: boolean;
  helperText?: string;
  currentFile: FileReference | null;
  showUploadedFiles?: boolean;
  setCurrentFile: (file: FileReference | null) => void;
}

const FileUploadButtonInner = ({
  accept,
  maxSize,
  disabled,
  helperText,
  currentFile,
  showUploadedFiles,
  setCurrentFile
}: FileUploadButtonInnerProps) => {
  if (currentFile) {
    return (
      <SelectedFile currentFile={currentFile} setCurrentFile={setCurrentFile} />
    );
  }

  return (
    <NoFileSelectedPaper
      maxSize={maxSize}
      accept={accept}
      showUploadedFiles={showUploadedFiles}
      setCurrentFile={setCurrentFile}
      currentFile={currentFile}
      disabled={disabled}
      helperText={helperText}
    />
  );
};

export default FileUploadButtonInner;
