import ChatThread from "./chatbox/ChatThread";
import useChatSocket from "./chatbox/hooks/useChatSocket";
import LeftPaperWrapper from "../LeftPaperWrapper";
import useGetCurrentChatThreadId from "./chatbox/hooks/useGetCurrentChatThreadId";

import { GetStartedChat } from "./GetStartedChat";

function ChatBoxPage() {
  useChatSocket();

  const threadId = useGetCurrentChatThreadId();

  return (
    <LeftPaperWrapper
      mainContent={threadId ? <ChatThread /> : <GetStartedChat />}
    />
  );
}

export default ChatBoxPage;
