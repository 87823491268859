import { FieldType, type TFieldsByType } from "@toolflow/shared";

export default function useYoutubeTranscriptFieldsConfig() {
  const configs: TFieldsByType = [
    {
      type: FieldType.DynamicInputField,
      label: "Url",
      subtitle: "Please type in a youtube URL to scrape its transcript",
      config: {
        fieldKey: "settings.url.value",
        typeKey: "settings.url.type",
        defaultValue: "",
        placeholder: `Example: https://www.youtube.com/watch?v=***`
      }
    },
    {
      type: FieldType.DynamicSwitchField,
      label: "Add timestamp",
      subtitle: "Enable this option to add timestamps to the transcript.",
      config: {
        typeKey: "settings.addTimestamp.type",
        fieldKey: "settings.addTimestamp.value",
        defaultValue: false
      }
    }
  ];

  return configs;
}
