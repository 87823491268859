import type { MessageSettings, TToolDetails } from "@toolflow/shared";
import { useGetThreadRunStepsByIdQuery } from "../../../../../../ToolflowAPI/rtkRoutes/threadsApi";
import ToolContextComponentContainer from "../../../../../tools/contexts/ToolContextContainer";
import useGetCurrentChatThreadId from "./hooks/useGetCurrentChatThreadId";
import ToolDetails from "./ToolDetails";
import { useEffect, useState } from "react";
import LoadingToolDetails from "./LoadingToolDetails";
import useIsMessageLoading from "./SendThreadTextField/hooks/useIsMessageLoading";
import ToolRunByUser from "./ToolRunByUser";

const ToolRunDetails = ({ runId }: { runId?: string | null }) => {
  const currentThreadId = useGetCurrentChatThreadId();
  const isLoading = useIsMessageLoading();
  const { data } = useGetThreadRunStepsByIdQuery(
    {
      threadId: currentThreadId,
      runId: runId || ""
    },
    {
      skip: !runId || !currentThreadId || isLoading,
      refetchOnMountOrArgChange: true
    }
  );

  if (!data || !data.result || !data.success) return null;

  const runSteps = data.result.runSteps;

  if (runSteps.length === 0) return null;

  return (
    <>
      {runSteps.map((step, idx) => (
        <ToolContextComponentContainer
          toolId={step.toolId}
          key={`${step.toolId}-${idx}`}
        >
          <ToolDetails inputs={step.inputs} output={step.outputs} />
        </ToolContextComponentContainer>
      ))}
    </>
  );
};

const ToolRunByAgentSplitter = ({
  runId,
  toolDetails
}: {
  runId?: string | null;
  toolDetails?: TToolDetails[];
}) => {
  if (runId) {
    return <ToolRunDetails runId={runId} />;
  }

  return (
    <>
      {toolDetails?.map((toolDetail) => (
        <ToolContextComponentContainer
          toolId={toolDetail.toolId}
          key={toolDetail.toolId}
        >
          <ToolDetails inputs={toolDetail.inputs} output={toolDetail.outputs} />
        </ToolContextComponentContainer>
      ))}
    </>
  );
};

const StepDetails = ({ settings }: { settings: MessageSettings }) => {
  const [toolCallsStatus, setToolCallsStatus] = useState<string[] | null>(
    settings?.toolCallsStatus || null
  );

  useEffect(() => {
    if (settings?.toolCallsStatus) {
      setToolCallsStatus((prev) => [
        ...(prev || []),
        ...(settings?.toolCallsStatus || [])
      ]);
    } else {
      setToolCallsStatus(null);
    }
  }, [settings?.toolCallsStatus]);

  if (toolCallsStatus) {
    return (
      <>
        {Object.values(toolCallsStatus).map((toolId, idx) => (
          <ToolContextComponentContainer toolId={toolId} key={idx}>
            <LoadingToolDetails />
          </ToolContextComponentContainer>
        ))}
      </>
    );
  }

  return (
    <>
      <ToolRunByAgentSplitter
        runId={settings?.runId} // when run has been completed, we can use this route (like when coming back to a thread)
        toolDetails={settings?.toolDetails} // when run has not been completed, we can use this route (like when sending a message)
      />
      <ToolRunByUser tvrId={settings?.tvrId} />
    </>
  );
};

export default StepDetails;
