import { Divider } from "@mui/material";
import React from "react";
import { parsedClassNames } from "../../../../../../../utilities/functions/parsedClassNames";
import Dots from "./Dots";
import MakeAssetListBigButton from "./MakeAssetListBigButton";
import MoveDownAnAssetButton from "./MoveDownAnAssetButton";
import MoveUpAnAssetButton from "./MoveUpAnAssetButton";
import CloseAssetPanelButton from "../../CloseAssetPanelButton";
import styles from "./smallAssetTOC.module.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../stores/store";

const cx = parsedClassNames.bind(styles);

function SmallAssetTOC() {
  const open = useSelector((state: RootState) => state.workspace.assetExpanded);

  return (
    <div
      className={
        "flex flex-column justify-space-between align-i-flex-end h-100-percent max-h-100-percent m-l-12px"
      }
    >
      {!open && (
        <div className="flex align-i-center justify-center w-100-percent m-b-16px">
          <CloseAssetPanelButton />
        </div>
      )}
      <div
        className={cx(
          styles["small-asset-button-group"],
          "flex align-i-center flex-column asset-toc-box-shadow"
        )}
      >
        <MakeAssetListBigButton />
        <Divider orientation="horizontal" className="m-v-16px" flexItem />
        <MoveUpAnAssetButton />
        <MoveDownAnAssetButton />
      </div>
      <Dots />
      <div />
    </div>
  );
}

export default SmallAssetTOC;
