import { Button, Chip, Paper, Stack, Typography } from "@mui/material";
import { useAgentContext } from "./contexts/AgentContext";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ToolContextComponentContainer from "../tools/contexts/ToolContextContainer";
import { useToolContext } from "../tools/contexts/ToolContext";
import useNavigateToToolById from "../pages/workstation/hooks/useNavigateToToolById";
import { InfoIcon } from "../../globalTheme/icons/icons";
import { GenericRichContent } from "../../utilities/components/textFields/workspace/GenericRichContent";
import { DialogButtonContainer } from "../../utilities/components/dialogs/DialogButtonContainer";
import RemixButton from "../../utilities/components/icons/RemixButton";
import "swiper/css";
import type { Content } from "@tiptap/core";
import { NavigateToOwnAgentBuilderButton } from "../pages/builder/agentBuilder/NavigateToAgentBuilderButton";

const GetStartedToolChip = () => {
  const { tool } = useToolContext();
  const navigate = useNavigateToToolById(tool._id);

  return (
    <Chip
      label={tool.name}
      size="medium"
      className="bg-color-white grey"
      clickable
      onClick={navigate}
    />
  );
};

export const GuidelinesPaper = ({
  content,
  className
}: {
  content: Content;
  className?: string;
}) => {
  return (
    <Paper
      className={`w-100-percent p-16px border-radius-16px ${className}`}
      variant="outlined"
    >
      <GenericRichContent content={content} className="w-100-percent" />
    </Paper>
  );
};

export const AgentInfo = ({
  showInstructions
}: {
  showInstructions?: boolean;
}) => {
  const { agent } = useAgentContext();
  return (
    <div className="max-w-100-percent">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        className="w-100-percent parent-hover"
      >
        <Typography variant="h6" align="center">
          {agent?.name}
        </Typography>
        <NavigateToOwnAgentBuilderButton
          agent={agent}
          className="show-on-hover"
        />
      </Stack>
      {agent?.description && (
        <div className="w-100-percent m-t-16px flex justify-center">
          <Typography
            variant="body1"
            align="center"
            className="grey max-w-540px oflow-two-lines"
          >
            {agent.description}
          </Typography>
        </div>
      )}
      <Swiper
        scrollbar
        slidesPerView={"auto"}
        spaceBetween={8}
        modules={[Pagination]}
        className="w-fit-content max-w-100-percent m-t-16px m-b-16px max-w-530px"
        navigation={true}
        pagination={{
          clickable: true
        }}
      >
        {agent?.config.toolIds.map((toolId, index) => {
          return (
            <SwiperSlide key={index} className="w-fit-content-important">
              <ToolContextComponentContainer toolId={toolId}>
                <GetStartedToolChip />
              </ToolContextComponentContainer>
            </SwiperSlide>
          );
        })}
      </Swiper>

      {agent?.userInstructions && (
        <div className="flex justify-center w-100-percent">
          {showInstructions ? (
            <GuidelinesPaper
              content={agent.userInstructions}
              className="m-t-24px"
            />
          ) : (
            <DialogButtonContainer
              button={
                <Button variant="outlined" className="m-t-16px bg-color-white">
                  How to use
                </Button>
              }
              dialogContentClassName="w-100-percent"
              content={() => <AgentInfo showInstructions />}
            />
          )}
        </div>
      )}
    </div>
  );
};

export const AgentInfoDialog = () => {
  const { agent } = useAgentContext();

  if (
    !agent ||
    (!agent.userInstructions &&
      !agent.description &&
      agent.config.toolIds.length === 0)
  )
    return null;

  return (
    <DialogButtonContainer
      button={<RemixButton onClick={() => {}} icon={InfoIcon} />}
      content={() => <AgentInfo showInstructions />}
    />
  );
};
