import type { Content } from "@tiptap/core";
import {
  type StringAsset,
  AssetSourceType,
  AssetValueType
} from "@toolflow/shared";
import { v4 as uuidv4 } from "uuid";
import { VARIABLE_NAME } from "../../helpers/workspaceConstants";

export const getNewStringAsset = ({
  value,
  id,
  name,
  version = 1,
  toolId,
  sourceIdentifier
}: {
  value?: Content;
  id?: string;
  name?: string;
  version?: number;
  toolId?: string;
  sourceIdentifier?: string;
}): StringAsset => {
  return {
    label: name || `New ${VARIABLE_NAME.toLowerCase()}`,
    name: name || `New ${VARIABLE_NAME.toLowerCase()}`,
    source: toolId ? AssetSourceType.ToolOutput : AssetSourceType.Manual,
    value: value || "",
    valueType: AssetValueType.String,
    id: id || uuidv4(),
    sourceIdentifier,
    data: {
      version,
      toolId
    }
  };
};
