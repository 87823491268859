import React from "react";
import RemixButton from "../../../../utilities/components/icons/RemixButton";
import { EditIcon } from "../../../../globalTheme/icons/icons";
import useNavigateToAgentBuilderById from "./hooks/useNavigateToAgentBuilderById";
import useGetCurrentUser from "../../../user/hooks/useGetCurrentUser";
import type { TUniversalAgent } from "@toolflow/shared";

function NavigateToAgentBuilderButton({
  agentId,
  providedColor,
  className = "",
  iconButtonClassName = ""
}: {
  agentId: string;
  providedColor?: string;
  className?: string;
  iconButtonClassName?: string;
}) {
  const navigateToAgentBuilder = useNavigateToAgentBuilderById(agentId);

  return (
    <RemixButton
      text="Edit"
      icon={EditIcon}
      onClick={navigateToAgentBuilder}
      disabled={!agentId}
      providedColor={providedColor}
      className={className}
      iconButtonClassName={iconButtonClassName}
    />
  );
}

export function NavigateToOwnAgentBuilderButton({
  agent,
  className
}: {
  agent: TUniversalAgent;
  className?: string;
}) {
  const user = useGetCurrentUser();
  return agent.userId === user?._id ? (
    <NavigateToAgentBuilderButton
      agentId={agent._id}
      iconButtonClassName={className}
    />
  ) : null;
}

export default NavigateToAgentBuilderButton;
