import { createTheme, responsiveFontSizes } from "@mui/material";
import toolflowTypography from "./toolflowTypography";
import { getCSSColor } from "./getCSSColor";

export const rightSideContentBackground = getCSSColor(
  "--right-side-content-background",
  "#faf8f7"
);
export const toolflowPrimaryColor = getCSSColor("--primary-color", "#8a2dff");
export const toolflowErrorColor = "#d32f2f";
export const toolflowTextDisabledColor = getCSSColor(
  "--toolflow-text-disabled-color",
  "#12172961"
);
export const toolflowTextPrimaryColor = getCSSColor(
  "--primary-text-color",
  "#121729"
);
export const toolflowTextPrimaryColorLight = getCSSColor(
  "--primary-text-color-light",
  "#12172980"
);
export const toolflowDarkBackground = "#616161e5";
export const toolflowWhiteColor = "white";
export const toolflowTextSecondaryColor = getCSSColor(
  "--secondary-text-color",
  "#12172999"
);
export const toolflowPrimaryButtonBorderColor = getCSSColor(
  "--primary-button-border-color",
  "#6d12df"
);
export const lightButtonBorder = getCSSColor("--button-border", "#e4dfd4");
export const toolflowTextColorOrange = "#ff5722";
export const toolflowTextColorPrimary = "#000";
export const toolflowSecondaryMain = "#d016ff";
export const toolflowAppBackground = getCSSColor(
  "--app-background-color",
  "#eee9e4"
);

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    purple: true;
  }
}

declare module "@mui/material/styles" {
  interface ChipVariants {
    purple: React.CSSProperties;
  }

  interface ChipVariantsOptions {
    purple: React.CSSProperties;
  }
}

const theme = createTheme({
  typography: toolflowTypography,
  palette: {
    mode: "light",
    primary: {
      main: toolflowPrimaryColor
    },
    error: {
      main: toolflowErrorColor
    },
    secondary: {
      main: toolflowSecondaryMain
    },
    textColor: {
      main: toolflowTextColorOrange
    },
    text: {
      primary: toolflowTextColorPrimary,
      secondary: toolflowTextSecondaryColor,
      disabled: toolflowTextDisabledColor
    }
  },

  components: {
    MuiButtonBase: {
      defaultProps: {
        disableTouchRipple: true // If we disable ripple update .Mui-focusVisible
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "&.MuiDialog-paper": {
            borderRadius: "8px"
          },
          "&.MuiMenu-paper": {
            borderRadius: "8px"
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        size: "small"
      },
      styleOverrides: {
        root: {
          textTransform: "inherit",
          fontWeight: 500,
          padding: "6px 16px",
          borderRadius: "8px"
        }
      },
      variants: [
        {
          props: { variant: "text" },
          style: {
            backgroundColor: "rgba(138, 45, 255, 0.04)"
          }
        }
      ]
    },
    MuiMenu: {
      defaultProps: {
        anchorOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "right"
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "8px !important",
          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: toolflowPrimaryColor
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 8
        }
      }
    },
    MuiIconButton: {
      defaultProps: {
        size: "small"
      }
    },
    MuiChip: {
      defaultProps: {
        size: "small"
      },
      variants: [
        {
          props: { variant: "purple" },
          style: {
            backgroundColor: "#F4EBFF",
            borderRadius: "4px"
          }
        }
      ]
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: "subtitle1" }
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          caption: "p"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "inherit"
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.notSelected": {
            "&.Mui-selected": {
              backgroundColor: "inherit"
              // fontWeight: "normal"
            },
            "&.Mui-selected:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.08)"
            }
          }
        }
      }
    }
  }
});

export const MAX_ROWS_LARGE_TEXTFIELD = 8;

const appTheme = responsiveFontSizes(theme);

export default appTheme;
