import React, { useState } from "react";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import { useAuthStore } from "../../../features/auth/hooks/useAuthStore";
import { toolflowAppBackground } from "../../../globalTheme/muiUtils/appTheme";
import { CloseIcon } from "../../../globalTheme/icons/icons";

const useDialog = () => {
  const [open, setOpen] = useState(false);
  const { isLoading } = useAuthStore();
  return { open, setOpen, disabled: isLoading };
};

export const DialogButtonContainer = ({
  button,
  content,
  dialogContentClassName
}: {
  button: React.ReactElement<{
    onClick?: (e: React.MouseEvent) => void;
    disabled?: boolean;
  }>;
  content: (props: { setOpen: (open: boolean) => void }) => React.ReactElement;
  dialogContentClassName?: string;
}) => {
  const { open, setOpen, disabled } = useDialog();

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        onClose={() => setOpen(false)}
        maxWidth="md"
        PaperProps={{
          className: "border-radius-16px",
          sx: { backgroundColor: toolflowAppBackground }
        }}
      >
        <div className="pos-relative">
          <IconButton
            onClick={() => {
              console.log("closing");
              setOpen(false);
            }}
            className="pos-absolute z-index-max"
            sx={{ top: 24, right: 24 }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent className={dialogContentClassName}>
            {content({ setOpen })}
          </DialogContent>
        </div>
      </Dialog>
      {React.cloneElement(button as React.ReactElement, {
        onClick: (e: React.MouseEvent) => {
          setOpen(true);
          const originalOnClick = (button as React.ReactElement).props.onClick;
          if (originalOnClick) {
            originalOnClick(e);
          }
        },
        disabled
      })}
    </>
  );
};
