import { useEffect, useState } from "react";

export default function useDragListener() {
  const [isDragging, setIsDragging] = useState(false);

  function handleDragEnter() {
    setIsDragging(true);
  }

  function handleDragLeave(e: DragEvent) {
    if (e && e.pageX == 0 && e.pageY == 0) {
      setIsDragging(false);
    }
  }

  function handleDrop() {
    setIsDragging(false);
  }

  useEffect(() => {
    window.addEventListener("dragenter", handleDragEnter);
    window.addEventListener("dragleave", handleDragLeave);
    window.addEventListener("drop", handleDrop);

    return () => {
      window.removeEventListener("dragenter", handleDragEnter);
      window.removeEventListener("dragleave", handleDragLeave);
      window.removeEventListener("drop", handleDrop);
    };
  }, []);

  return isDragging;
}
