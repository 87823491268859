import React from "react";
import ToolflowHeader from "../../../utilities/components/headers/toolflowHeader/ToolflowHeader";
import LearnMoreAboutKnowledge from "./LearnMoreAboutKnowledge/LearnMoreAboutKnowledge";
import SnippetsCard from "./snippetsCard/SnippetsCard";
import CenterContentContainer from "../../../utilities/components/containers/CenterContentContainer";
import { useGetSnippetsQuery } from "../../../ToolflowAPI/rtkRoutes/snippetsApi";
import EntityNotFoundBase from "../../../utilities/components/autocomplete/universalAutocomplete/EntityNotFoundBase";
import { KnowledgeIcon } from "../../../globalTheme/icons/icons";
import AddSnippetButtonOnKnowledgePage from "./snippetsCard/AddSnippetButtonOnKnowledgePage";

function KnowledgePageInner() {
  const { data } = useGetSnippetsQuery();
  const snippets = data?.ids || [];
  return (
    <ToolflowHeader title="Knowledge">
      <CenterContentContainer>
        <div className="p-h-16px m-t-16px">
          <LearnMoreAboutKnowledge />
        </div>
        <div className="p-h-16px m-t-16px">
          {snippets.length !== 0 ? (
            <SnippetsCard />
          ) : (
            <EntityNotFoundBase
              className="flex align-i-center justify-center flex-column h-450px m-h-auto"
              icon={<KnowledgeIcon />}
              header="No sources added"
              subHeader="Add sources to your knowledge base to help your agents and tools perform better"
              actionButton={<AddSnippetButtonOnKnowledgePage />}
            />
          )}
        </div>
      </CenterContentContainer>
    </ToolflowHeader>
  );
}

export default KnowledgePageInner;
