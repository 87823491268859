import {
  DynamicFieldType,
  type YoutubeTranscriptBlockSettings
} from "@toolflow/shared";

export const youtubeTranscriptInitialState: {
  settings: YoutubeTranscriptBlockSettings;
} = {
  settings: {
    url: { type: DynamicFieldType.Dynamic, value: "" },
    addTimestamp: { type: DynamicFieldType.Preset, value: false }
  }
};
