import {
  type FieldConfig,
  type YoutubeTranscriptBlockProps
} from "@toolflow/shared";
import FieldsByType from "../../common/fieldsByType/FieldsByType";
import useSyncCurrentAndPrevSettings from "../../hooks/useSyncCurrentAndPrevSettings";
import useUpdateField from "../../useUpdateField";
import useYoutubeTranscriptFieldsConfig from "./useYoutubeTranscriptFieldsConfig";

export default function YoutubeTranscriptBlockInner({
  data,
  id
}: Omit<YoutubeTranscriptBlockProps, "selected">) {
  const { settings } = data;
  useSyncCurrentAndPrevSettings(settings);

  const fields = useYoutubeTranscriptFieldsConfig();
  const updateField = useUpdateField(id);

  const configs: FieldConfig = {
    id,
    data,
    updateField,
    fields
  };

  return (
    <div className="m-b-32px m-t-16px">
      <FieldsByType configs={configs} />
    </div>
  );
}
