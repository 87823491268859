import { Button, Chip, FormHelperText, TextField } from "@mui/material";
import React, { ChangeEvent, FC, useState } from "react";
import type { TWordPairsDict } from "@toolflow/shared";

interface IReplaceWordsFormProps {
  wordsToReplace: TWordPairsDict;
  handleChange: (pairs: TWordPairsDict) => void;
  disableHelperText?: boolean;
}

const ReplaceWordsForm: FC<IReplaceWordsFormProps> = (props) => {
  const { handleChange, wordsToReplace, disableHelperText } = props;
  const [pairsDict, setPairsDict] = useState<TWordPairsDict>(
    wordsToReplace || {}
  );
  const [pairKey, setPairKey] = useState<string>("");
  const [pairValue, setPairValue] = useState<string>("");

  const handleAddPair = (newPairKey: string, newPairValue: string) => {
    if (pairKey && pairValue) {
      const newPairsDict = { ...pairsDict, [newPairKey]: newPairValue };
      setPairsDict(newPairsDict);
      handleChange(newPairsDict);
      setPairKey("");
      setPairValue("");
    }
  };

  const handleChangePairState = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "pairKey") {
      setPairKey(value);
    } else {
      setPairValue(value);
    }
  };

  const handleDeletePair = (pairKeyString: string) => {
    const copiedPairDict = { ...pairsDict };
    delete copiedPairDict[pairKeyString];
    setPairsDict(copiedPairDict);
    handleChange(copiedPairDict);
  };

  const handleFocusAway = () => {
    if (pairKey && pairValue) {
      handleAddPair(pairKey, pairValue);
    }
  };
  return (
    <>
      {Object.entries(pairsDict).map(([pairKeyString, pairKeyValue], index) => {
        return (
          <Chip
            key={index}
            variant="outlined"
            color="primary"
            className="m-r-8px m-b-8px"
            label={`${pairKeyString}: ${pairKeyValue}`}
            onDelete={() => {
              handleDeletePair(pairKeyString);
            }}
          />
        );
      })}
      <div className="flex align-i-center min-w-300px">
        <TextField
          label="Search for"
          variant="outlined"
          value={pairKey}
          name="pairKey"
          className="bg-color-white"
          onChange={handleChangePairState}
          onKeyDown={(e) => {
            const charAtCursor = e.key;
            if (charAtCursor === "Enter") {
              handleFocusAway();
            }
          }}
        />
        <TextField
          label="Replace with"
          className="m-h-8px bg-color-white"
          variant="outlined"
          value={pairValue}
          name="pairValue"
          onChange={handleChangePairState}
          onKeyDown={(e) => {
            const charAtCursor = e.key;
            if (charAtCursor === "Enter") {
              handleFocusAway();
            }
          }}
        />
        <Button
          color="primary"
          variant="outlined"
          aria-label="delete"
          onClick={() => handleAddPair(pairKey, pairValue)}
        >
          Add
        </Button>
      </div>
      {!disableHelperText && (
        <FormHelperText>
          These words will be replaced every time the tool is run.
        </FormHelperText>
      )}
    </>
  );
};

export default ReplaceWordsForm;
