import {
  BlockType,
  DEFAULT_VERSION_VALUE,
  UtilBlockType,
  VERSION_2_VALUE,
  type AllBlockTypes,
  type BlockFunctionRecords,
  type DecimalString
} from "@toolflow/shared";
import * as fns from "./fns";

const blockConfigFunctions = Object.values({
  ...BlockType,
  ...UtilBlockType
}).reduce((acc, blockType) => {
  const versions = [DEFAULT_VERSION_VALUE, VERSION_2_VALUE];
  acc[blockType] = versions.reduce(
    (acc2, ver) => {
      acc2[ver] = {
        validate: fns.getBlockValidatorByVersion(blockType, ver),
        updateInput: fns.getBlockInputUpdaterFunction(blockType, ver),
        getNestedFields: fns.getNestedFieldFnsByBlockType(blockType, ver),
        getOutputOptions: fns.getOutputOptionsFnsByBlockType(blockType, ver),
        getOutputConfigOptions: fns.getOutputConfigOptionsFn(blockType, ver)
      };
      return acc2;
    },
    {} as BlockFunctionRecords[string]
  );
  return acc;
}, {} as BlockFunctionRecords);

export default function getBlockConfigFunctions(
  blockType: AllBlockTypes,
  version: DecimalString = DEFAULT_VERSION_VALUE
) {
  return blockConfigFunctions[blockType]?.[version] || {};
}
