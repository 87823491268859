import {
  DeepgramModel,
  DynamicFieldType,
  type DeepgramBlockSettingsV1,
  type DeepgramBlockSettingsV2
} from "@toolflow/shared";

export const deepgramTranscribePromptInitialStateV1: {
  settings: DeepgramBlockSettingsV1;
} = {
  settings: {
    language: "en",
    file: "",
    model: DeepgramModel.Nova2,
    smartFormat: true,
    diarize: true,
    addTimestamps: false,
    addSummary: false,
    convertMeasurementsValuesToAbbreviations: false,
    keywords: [],
    userKeywordsFieldKey: "",
    wordsToReplace: {},
    userWordsToReplaceFieldKey: "",
    isFileDictation: false,
    timeStampByEntity: "paragraph"
  }
};

export const deepgramTranscribePromptInitialStateV2: {
  settings: DeepgramBlockSettingsV2;
} = {
  settings: {
    file: "",
    wordsToReplace: {},
    timeStampByEntity: { type: DynamicFieldType.Preset, value: "paragraph" },
    userWordsToReplaceFieldKey: "",
    model: { type: DynamicFieldType.Preset, value: DeepgramModel.Nova2 },
    language: { type: DynamicFieldType.Preset, value: "en" },
    smartFormat: { type: DynamicFieldType.Preset, value: true },
    diarize: { type: DynamicFieldType.Preset, value: true },
    addTimestamps: { type: DynamicFieldType.Preset, value: false },
    addSummary: { type: DynamicFieldType.Preset, value: false },
    convertMeasurementsValuesToAbbreviations: {
      type: DynamicFieldType.Preset,
      value: false
    },
    keywords: { type: DynamicFieldType.Preset, value: [] },
    userKeywordsFieldKey: { type: DynamicFieldType.Preset, value: "" },
    isFileDictation: { type: DynamicFieldType.Preset, value: false }
  }
};
