import { useSelector } from "react-redux";
import useFormIsDirty from "../../../../../../utilities/hooks/useIsDirty";
import { RootState } from "../../../../../../stores/store";

const useDisableAgentSave = () => {
  const { isDirty } = useFormIsDirty();
  const toolsOutdated = useSelector(
    (state: RootState) => state.agentBuilder.toolsOutdated
  );
  return !isDirty && !toolsOutdated;
};

export default useDisableAgentSave;
