export const DOCUMENT_FILETYPE = ["txt", "doc", "docx"];

export const AUDIO_FILETYPE = [
  "mp3",
  "mp4",
  "mpeg",
  "m4a",
  "mpga",
  "wav",
  "webm"
];

export const FIVE_MEGA_BYTES_IN_BYTES = 5242880;
export const ONE_MEGA_BYTE_IN_BYTES = 1048576;
