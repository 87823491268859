import { UserIntegrationType } from "@toolflow/shared";
import {
  DriveIcon,
  LinkedInIcon,
  NotionIcon,
  SlackIcon
} from "../../../../../globalTheme/icons/icons";

export interface Integration {
  type: UserIntegrationType;
  name: string;
  description: string;
  icon: JSX.Element | string;
  brandColor: string;
}

export const integrationsList: Integration[] = [
  {
    type: UserIntegrationType.SLACK,
    name: "Slack",
    description: "Connect to Slack for team collaboration.",
    icon: <SlackIcon />,
    brandColor: "#4A154B"
  },
  {
    type: UserIntegrationType.SALESFORCE,
    name: "Salesforce",
    description: "Sync your data with Salesforce CRM.",
    icon: "https://www.salesforce.com/news/wp-content/uploads/sites/3/2021/05/Salesforce-logo.jpg",
    brandColor: "#00A1E0"
  },
  {
    type: UserIntegrationType.AIRTABLE,
    name: "Airtable",
    description: "Integrate with Airtable to manage and sync your data.",
    icon: "https://images.seeklogo.com/logo-png/29/1/airtable-logo-png_seeklogo-294322.png",
    brandColor: "#FCB400"
  },
  {
    type: UserIntegrationType.CALENDLY,
    name: "Calendly",
    description: "Connect to Calendly to streamline scheduling workflows.",
    icon: "https://assets.calendly.com/assets/frontend/media/logo-square-cd364a3c33976d32792a.png",
    brandColor: "#006BFF"
  },
  {
    type: UserIntegrationType.HUBSPOT,
    name: "HubSpot",
    description: "Sync with Hubspot CRM for marketing and sales automation.",
    icon: "https://www.hubspot.com/hubfs/HubSpot_Logos/HubSpot-Inversed-Favicon.png",
    brandColor: "#FF7A59"
  },
  {
    type: UserIntegrationType.GOOGLE_DRIVE,
    name: "Google Drive",
    description: "Access and manage your files in Google Drive.",
    icon: <DriveIcon />,
    brandColor: "#4285F4"
  },
  {
    type: UserIntegrationType.GOOGLE_DOCS,
    name: "Google Docs",
    description: "Create and collaborate on documents seamlessly.",
    icon: "https://ssl.gstatic.com/images/branding/product/1x/docs_2020q4_48dp.png",
    brandColor: "#4285F4"
  },
  {
    type: UserIntegrationType.GMAIL,
    name: "Gmail",
    description: "Integrate email communications with your workflow.",
    icon: "https://www.gstatic.com/images/branding/product/1x/gmail_2020q4_48dp.png",
    brandColor: "#EA4335"
  },
  {
    type: UserIntegrationType.GOOGLE_SHEETS,
    name: "Google Sheets",
    description: "Work with spreadsheets and data analysis tools.",
    icon: "https://www.gstatic.com/images/branding/product/1x/sheets_2020q4_48dp.png",
    brandColor: "#0F9D58"
  },
  {
    type: UserIntegrationType.GOOGLE_CALENDAR,
    name: "Google Calendar",
    description: "Manage your schedule and appointments efficiently.",
    icon: "https://www.gstatic.com/images/branding/product/1x/calendar_2020q4_48dp.png",
    brandColor: "#4285F4"
  },
  {
    type: UserIntegrationType.LINKEDIN,
    name: "LinkedIn",
    description: "Connect with your professional network and share updates.",
    icon: <LinkedInIcon />,
    brandColor: "#0A66C2"
  },
  {
    type: UserIntegrationType.NOTION,
    name: "Notion",
    description: "Organize and collaborate on workspace documents.",
    icon: <NotionIcon />,
    brandColor: "#000000"
  }
];
