import { FC } from "react";
import type { TTransformedMessage } from "@toolflow/shared";
import StepDetails from "./StepDetails";
import AgentMessage from "./AgentMessage";
interface IAgentMessageProps {
  message: TTransformedMessage;
}

const AgentMessageWrapper: FC<IAgentMessageProps> = (props) => {
  const { message } = props;
  const { content, settings } = message;

  return (
    <div className="w-100-percent m-t-20px">
      <StepDetails settings={settings} />
      <AgentMessage content={content} />
    </div>
  );
};

export default AgentMessageWrapper;
