import { Box } from "@mui/material";
import React from "react";
import useGetElementHeight from "./assets/asset/textAsset/hooks/useGetElementHeight";
import { classNames } from "../../../../utilities/functions/parsedClassNames";

function LeftPaperWrapper({
  header,
  headerClassName = "",
  className = "",
  mainContent
}: {
  header?: React.ReactNode;
  headerClassName?: string;
  mainContent: React.ReactNode;
  className?: string;
}) {
  const { ref, height } = useGetElementHeight();
  return (
    <>
      <div ref={ref} className={headerClassName}>
        {header}
      </div>
      <Box
        className={classNames(
          "pos-relative h-100-percent border-radius-bottom-16px",
          className
        )}
        sx={{
          maxHeight: `calc(100% - ${height}px)`
        }}
      >
        {mainContent}
      </Box>
    </>
  );
}

export default LeftPaperWrapper;
