import { Chip } from "@mui/material";
import { NodeViewWrapper, type NodeViewProps } from "@tiptap/react";
import { ChipNodeHtmlTags } from "@toolflow/shared";
import { AssetIcon } from "../../../../../globalTheme/icons/icons";
import useAddNewAsset from "../../../../../features/pages/workstation/assets/useAddNewAsset";
import useWorkspaceAssets from "../../../../../features/pages/workstation/assets/useWorkspaceAssets";
import { useEffect, useMemo } from "react";
import { useUpdateAsset } from "../../../../../features/pages/workstation/components/assets/asset/textAsset/hooks/useAssetTextField";
import useSetVisibleAsset from "../../../../../features/pages/workstation/assets/useSetVisibleAsset";
import { getTruncatedCharacters } from "../../../../formatters/strings/getTruncatedCharacters";

const FlowArtifactViewerComponent = (props: NodeViewProps) => {
  const addAssetContent = useAddNewAsset();

  const assets = useWorkspaceAssets();

  const asset = useMemo(() => {
    return assets.filter(
      (a) =>
        a.id === props.node.attrs.identifier ||
        // this is so that when a tooloutput gets put onto the chat, it doesnt open up the asset
        (a.sourceIdentifier === props.node.attrs.sourceIdentifier &&
          a.name === props.node.attrs.title)
    )[0];
  }, [props.node.attrs.identifier, assets]);
  const onUpdate = useUpdateAsset(asset);
  const { setVisibleAsset } = useSetVisibleAsset();

  const getRawHtml = () => {
    const content = props.node.attrs.rawHTML;
    return content;
  };

  const handleClick = () => {
    const content = getRawHtml();
    if (!asset) {
      addAssetContent({
        value: content,
        valueType: props.node.attrs.type,
        name: props.node.attrs.title,
        id: props.node.attrs.identifier,
        toolId: props.node.attrs.toolId,
        sourceIdentifier: props.node.attrs.sourceIdentifier
      });
    } else if (asset.data?.version !== props.node.attrs.version) {
      onUpdate(content, props.node.attrs.title, props.node.attrs.version);
      setVisibleAsset(asset.id);
    } else {
      setVisibleAsset(asset.id);
    }
  };

  useEffect(() => {
    if (props.node.textContent && !props.node.attrs.sourceIdentifier) {
      if (!asset) {
        handleClick();
      } else {
        const content = getRawHtml();
        onUpdate(content);
      }
    }
  }, [props.node.content]);

  return (
    <NodeViewWrapper
      className={`inline-flex ${ChipNodeHtmlTags.FlowArtifactComponent} dontTriggerBubble w-100-percent`}
    >
      <Chip
        icon={<AssetIcon size={16} />}
        label={getTruncatedCharacters(props.node.attrs.title, 28)}
        className="p-h-12px p-v-24px cursor-pointer m-v-8px flex justify-flex-start"
        onClick={handleClick}
        style={{ width: 260 }}
      />
    </NodeViewWrapper>
  );
};

export default FlowArtifactViewerComponent;
