import {
  BlockType,
  DEFAULT_VERSION_VALUE,
  DynamicFieldType,
  InputType,
  UtilBlockType,
  VERSION_2_VALUE,
  deepCopy,
  returnNewInputMap,
  updateDeepContentName,
  updateNodeTargetInString,
  updateStringWithNewLabel,
  type AllBlockTypes,
  type BlockInputUpdater,
  type DecimalString,
  type LogicItem,
  type SerpBlockDataV1,
  type TBlock
} from "@toolflow/shared";
import {
  get as getValueFromPath,
  isArray,
  isObject,
  isString,
  isUndefined,
  set as setValueFromPath
} from "lodash";

function updateStringWithinBrackets(
  block: TBlock,
  path: string,
  oldName: string,
  newName?: string,
  deleteBrackets?: boolean
) {
  const data = deepCopy(block.data);
  const oldValue = getValueFromPath(data, path);
  if (oldValue) {
    const newValue = updateStringWithNewLabel({
      newName,
      deleteBrackets,
      deletedToolInputFieldName: oldName,
      stringToUpdate: oldValue
    });
    setValueFromPath(data, path, newValue);
  }
  return { ...block, data };
}

function updateJsonContent(
  block: TBlock,
  path: string,
  oldName: string,
  newName?: string
) {
  const data = deepCopy(block.data);
  const oldValue = getValueFromPath(data, path);
  if (oldValue) {
    const newValue = updateDeepContentName(oldValue, oldName, newName);
    setValueFromPath(data, path, newValue);
  }

  return { ...block, data };
}

/**
 * Replaces all deeply nested dynamic properties with target node's updated name
 *
 * **Important:** Does in-place update and does not create a copy.
 */
function updateNodeTargetsInDynamicProperties<
  T extends Record<$TSAllowedAny, $TSAllowedAny>
>({ obj, oldName, newName }: { obj: T; oldName: string; newName?: string }) {
  Object.keys(obj).forEach((key) => {
    const inner = obj[key];

    if (inner?.type === DynamicFieldType.Dynamic) {
      const path = `${key}.value`;
      const stringToUpdate = getValueFromPath(obj, path);
      if (isString(stringToUpdate)) {
        const newValue = updateNodeTargetInString({
          oldName,
          newName,
          stringToUpdate
        });
        setValueFromPath(obj, path, newValue);
      }
    } else if (isObject(inner)) {
      const updatedInner = updateNodeTargetsInDynamicProperties({
        obj: inner,
        oldName,
        newName
      });
      setValueFromPath(obj, key, updatedInner);
    }
  });

  return obj;
}

function updatePromptAndOptimizations({
  block,
  oldName,
  newName,
  promptKey
}: {
  block: TBlock;
  oldName: string;
  promptKey: string;
  newName?: string;
}) {
  let updatedBlock: TBlock = updateJsonContent(
    block,
    promptKey,
    oldName,
    newName
  );

  updatedBlock = updateNodeTargetsInDynamicProperties<TBlock>({
    obj: updatedBlock,
    oldName,
    newName
  });

  return updatedBlock;
}

function updateValueAtPaths(
  block: TBlock,
  paths: string[],
  oldName: string,
  newName = ""
): TBlock {
  const data = deepCopy<TBlock["data"]>(block.data);
  paths.forEach((path) => {
    const stringToUpdate = getValueFromPath(data, path);
    const updatedString = updateNodeTargetInString({
      oldName,
      newName,
      stringToUpdate
    });
    setValueFromPath(data, path, updatedString);
  });
  return { ...block, data };
}

export const blockInputUpdater: BlockInputUpdater = {
  [BlockType.ChatGPT]: {
    [DEFAULT_VERSION_VALUE]: (block, oldName, newName, deleteBrackets) =>
      updateStringWithinBrackets(
        block,
        "prompt",
        oldName,
        newName,
        deleteBrackets
      ),
    [VERSION_2_VALUE]: (block, oldName, newName) =>
      updateJsonContent(block, "settings.prompt", oldName, newName)
  },
  [BlockType.DallE]: {
    [DEFAULT_VERSION_VALUE]: (block, oldName, newName, deleteBrackets) =>
      updateStringWithinBrackets(
        block,
        "prompt",
        oldName,
        newName,
        deleteBrackets
      ),
    [VERSION_2_VALUE]: (block, oldName, newName) =>
      updatePromptAndOptimizations({
        block,
        oldName,
        newName,
        promptKey: "settings.prompt"
      })
  },
  [BlockType.Deepgram]: {
    [DEFAULT_VERSION_VALUE]: (block, oldName, newName) => {
      if (oldName !== "") {
        const data = deepCopy(block.data);
        if (data.settings.file === oldName) {
          data.settings.file = newName;
        } else if (data.settings.userKeywordsFieldKey === oldName) {
          data.settings.userKeywordsFieldKey = newName;
        } else if (data.settings.userWordsToReplaceFieldKey === oldName) {
          data.settings.userWordsToReplaceFieldKey = newName;
        }
        return { ...block, data };
      }
      return block;
    },
    [VERSION_2_VALUE]: (block, oldName, newName) => {
      const paths = [
        "settings.file",
        "settings.userKeywordsFieldKey",
        "settings.userWordsToReplaceFieldKey"
      ];

      const updatedBlock = updateValueAtPaths(block, paths, oldName, newName);

      return updateNodeTargetsInDynamicProperties({
        obj: updatedBlock,
        oldName,
        newName
      });
    }
  },
  [BlockType.Scraper]: {
    [DEFAULT_VERSION_VALUE]: (block, oldName, newName, deleteBrackets) =>
      updateStringWithinBrackets(
        block,
        "settings.urlFieldInputKey",
        oldName,
        newName,
        deleteBrackets
      ),
    [VERSION_2_VALUE]: (block, oldName, newName) =>
      updatePromptAndOptimizations({
        block,
        oldName,
        newName,
        promptKey: "settings.url"
      })
  },
  [BlockType.SERP]: {
    [DEFAULT_VERSION_VALUE]: (block, oldName, newName) => {
      const data = deepCopy(block.data) as SerpBlockDataV1;
      const queries = data.settings.queries;
      if (
        data.settings.inputType === InputType.BlockInput &&
        isArray(queries)
      ) {
        if (isString(newName)) {
          data.settings.queries = queries.map((query) => {
            if (query === oldName) return newName;
            return query;
          });
        } else {
          data.settings.queries = queries.filter((query) => query !== oldName);
        }
      }

      return { ...block, data };
    },
    [VERSION_2_VALUE]: (block, oldName, newName) => {
      return updateNodeTargetsInDynamicProperties<TBlock>({
        obj: deepCopy(block),
        oldName,
        newName
      });
    }
  },
  [BlockType.Perplexity]: {
    [DEFAULT_VERSION_VALUE]: (block, oldName, newName, deleteBrackets) =>
      updateStringWithinBrackets(
        block,
        "settings.input",
        oldName,
        newName,
        deleteBrackets
      ),
    [VERSION_2_VALUE]: (block, oldName, newName) => {
      return updatePromptAndOptimizations({
        block,
        oldName,
        newName,
        promptKey: "settings.input"
      });
    }
  },
  [BlockType.Structured]: {
    [DEFAULT_VERSION_VALUE]: (block, oldName, newName, deleteBrackets) =>
      updateStringWithinBrackets(
        block,
        "settings.input",
        oldName,
        newName,
        deleteBrackets
      ),
    [VERSION_2_VALUE]: (block, oldName, newName) =>
      updateJsonContent(block, "settings.input", oldName, newName)
  },
  [BlockType.IterationStart]: {
    [DEFAULT_VERSION_VALUE]: (block, oldName, newName) => {
      const updated = updateValueAtPaths(
        block,
        ["settings.input"],
        oldName,
        newName
      );
      return updated;
    }
  },
  [BlockType.Knowledge]: {
    [DEFAULT_VERSION_VALUE]: (block, oldName, newName) => {
      const updated = updateValueAtPaths(
        block,
        ["settings.query"],
        oldName,
        newName
      );
      return updated;
    }
  },
  [BlockType.SavetoKnowledge]: {
    [DEFAULT_VERSION_VALUE]: (block, oldName, newName) => {
      const updatedBlock = updateNodeTargetsInDynamicProperties<TBlock>({
        obj: deepCopy(block),
        oldName,
        newName
      });

      return updateValueAtPaths(
        updatedBlock,
        ["settings.content"],
        oldName,
        newName
      );
    }
  },
  [BlockType.YoutubeTranscript]: {
    [DEFAULT_VERSION_VALUE]: (block, oldName, newName) => {
      const updatedBlock = updateNodeTargetsInDynamicProperties<TBlock>({
        obj: deepCopy(block),
        oldName,
        newName
      });

      return updateValueAtPaths(
        updatedBlock,
        ["settings.url"],
        oldName,
        newName
      );
    }
  },
  [UtilBlockType.Logic]: {
    [DEFAULT_VERSION_VALUE]: (block, oldName, newName) => {
      if (isUndefined(newName)) {
        return {
          ...block,
          data: {
            ...block.data,
            logicArray: block.data.logicArray.filter(
              (logic: LogicItem) => logic.input !== oldName
            )
          }
        };
      } else {
        return {
          ...block,
          data: {
            ...block.data,
            logicArray: block.data.logicArray.map((logic: LogicItem) =>
              logic.input === oldName ? { ...logic, input: newName } : logic
            )
          }
        };
      }
    }
  },
  [UtilBlockType.Constant]: {
    [DEFAULT_VERSION_VALUE]: (block, oldName, newName = "") => {
      const newInputMap = returnNewInputMap(
        block.data.inputMap,
        oldName,
        newName
      );
      return {
        ...block,
        data: {
          ...block.data,
          inputMap: newInputMap
        }
      };
    },
    [VERSION_2_VALUE]: (block, oldName, newName) => {
      return updateJsonContent(block, "constant", oldName, newName);
    }
  },
  [UtilBlockType.ToolWithinTool]: {
    [DEFAULT_VERSION_VALUE]: (block, oldName, newName, deleteBrackets) =>
      updateStringWithinBrackets(
        block,
        "constant",
        oldName,
        newName,
        deleteBrackets
      ),
    [VERSION_2_VALUE]: (block, oldName, newName) =>
      updateJsonContent(block, "constant", oldName, newName)
  }
};

export function getBlockInputUpdaterFunction(
  blockType: AllBlockTypes,
  version: DecimalString = DEFAULT_VERSION_VALUE
) {
  return (
    blockInputUpdater[blockType]?.[version] ||
    blockInputUpdater[blockType]?.[DEFAULT_VERSION_VALUE]
  );
}
