import React from "react";
import SheetSettingsDropdown from "./SheetSettingsDropdown";

const SheetAssetIcons = () => {
  return (
    <div className="flex align-i-center">
      <SheetSettingsDropdown />
    </div>
  );
};

export default SheetAssetIcons;
