import {
  Typography,
  type TypographyProps,
  type TypographyVariant
} from "@mui/material";
import React, { ReactNode, useState } from "react";
import InfoIconWithTooltip from "../toolTips/InfoIconWithTooltip";
import useClickOutsideListener from "../../hooks/useClickOutsideListener";

function ToggleTextField({
  value,
  children,
  id,
  variant,
  color,
  className = "",
  defaultOpen,
  wrapperClassName = "",
  iconHelperText = "",
  typographyClassName = "",
  disableFlexGrow,
  disabled = false,
  errorMessage = <></>
}: {
  value: string;
  children: ReactNode;
  id?: string;
  variant?: TypographyVariant | "h7" | "h9";
  color?: TypographyProps["color"];
  className?: string;
  defaultOpen?: boolean;
  wrapperClassName?: string;
  iconHelperText?: string;
  typographyClassName?: string;
  disableFlexGrow?: boolean;
  disabled?: boolean;
  errorMessage?: ReactNode;
}) {
  const [updateOpen, setUpdateOpen] = useState(defaultOpen);
  const open = () => {
    if (!disabled) {
      setUpdateOpen(true);
    }
  };
  const close = () => setUpdateOpen(false);

  const wrapperRef = useClickOutsideListener(close);

  if (!updateOpen) {
    return (
      <div
        className={`flex cursor-pointer ${
          !disableFlexGrow ? "flex-grow-1" : ""
        } ${className}`}
      >
        <div className="oflow-hidden">
          <div className="flex align-i-center">
            <Typography
              onClick={open}
              id={id}
              noWrap
              className={typographyClassName}
              variant={variant}
              color={color}
              sx={{
                color: (theme) =>
                  disabled ? theme.palette.action.disabled : undefined
              }}
            >
              {value}
            </Typography>
            <InfoIconWithTooltip text={iconHelperText} className="m-l-8px" />
          </div>
          {errorMessage}
        </div>
      </div>
    );
  }
  return (
    <div ref={wrapperRef} className={wrapperClassName}>
      {children}
      {errorMessage}
    </div>
  );
}

export default ToggleTextField;
