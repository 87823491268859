import React, { useState } from "react";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { useStartImpersonationMutation } from "../../../../../../ToolflowAPI/rtkRoutes/impersonationApi";
import ToolflowEmployeeSplitter from "../../../../../auth/ToolflowEmployeeGuard";

export const ImpersonationSettings = () => {
  const [targetUserId, setTargetUserId] = useState("");
  const [startImpersonation, { isLoading }] = useStartImpersonationMutation();

  const handleStartImpersonation = async () => {
    if (!targetUserId) return;
    await startImpersonation({ targetUserId });
  };

  return (
    <ToolflowEmployeeSplitter
      component={
        <Box>
          <Typography variant="h6" gutterBottom>
            User Impersonation
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Enter a user ID to impersonate that user. This feature is only
            available to Toolflow employees.
          </Typography>
          <Stack
            flexDirection="row"
            gap={2}
            alignItems="flex-start"
            className="m-t-16px"
          >
            <TextField
              label="User ID"
              value={targetUserId}
              onChange={(e) => setTargetUserId(e.target.value)}
              variant="outlined"
              size="small"
              className="min-w-300px bg-color-white"
            />
            <Button
              variant="contained"
              onClick={handleStartImpersonation}
              disabled={!targetUserId || isLoading}
            >
              {isLoading ? "Starting..." : "Start Impersonation"}
            </Button>
          </Stack>
        </Box>
      }
    />
  );
};
