import Slider, { type SliderOwnProps } from "@mui/material/Slider";
import type { SliderFieldProps } from "@toolflow/shared";
import useFieldsByTypeHelper from "../useFieldsByTypeHelper";
import InputLabelWrapper from "./helpers/InputLabelWrapper";
import { useFieldsByTypeContext } from "../FieldsByTypeContext";

export default function SliderField({ field, settings }: SliderFieldProps) {
  const { config, label, subtitle, disabled } = field;
  const { fieldKey, marksMax = 100, className } = config;
  const { getFieldValue } = useFieldsByTypeHelper({ settings });
  const { updateField } = useFieldsByTypeContext();

  const onChange: SliderOwnProps["onChange"] = (_, newValue) => {
    updateField(newValue as number, fieldKey);
    console.log(newValue);
  };

  return (
    <>
      <InputLabelWrapper
        label={label}
        subtitle={subtitle}
        className={className}
      >
        <Slider
          disabled={disabled}
          className="nowheel nodrag nopan"
          value={getFieldValue(fieldKey, 0)}
          onChange={onChange}
          valueLabelDisplay="auto"
          step={marksMax ? null : undefined} // If max marks exist, we want steps to be limited to the marks max array
          scale={(value) => value / 50}
          marks={Array.from({ length: marksMax + 1 }, (_, i) => ({ value: i }))}
        />
      </InputLabelWrapper>
    </>
  );
}
