import type { TAvailableTiers } from "@toolflow/shared";
import { FREE_PLAN_FE } from "../../../../../billing/helpers/billingConstants";
import { useAuthorizedTier } from "../../../../../billing/hooks/useAuthorizedTier";

export const getAllowedAPIKeys = (tier: TAvailableTiers) => {
  return tier !== FREE_PLAN_FE;
};

const useAllowAPIKeys = () => {
  const authorizedTier = useAuthorizedTier();
  return getAllowedAPIKeys(authorizedTier);
};

export default useAllowAPIKeys;
