import type { TMessageByThreadId } from "@toolflow/shared";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../stores/store";
import useIsMessageLoading from "../SendThreadTextField/hooks/useIsMessageLoading";
import { getTransformedMessages } from "../utils/getTransformedMessages";
import useThreadMessages from "./useThreadMessages";

const useChatMessages = () => {
  // we need to stay subscribed to the thread
  useThreadMessages();
  const messages = useSelector((store: RootState) => store.chat.messages);
  const hasSocketsFinished = messages[messages.length - 1]?.isLastMessage;

  let socketMessages: TMessageByThreadId[] = [];
  let nonSocketMessages: TMessageByThreadId[] = [];
  if (hasSocketsFinished === undefined || hasSocketsFinished) {
    nonSocketMessages = messages;
    socketMessages = [];
  } else {
    nonSocketMessages = messages.slice(0, messages.length - 1);
    const messagesWithoutLastSocket = messages.filter(
      (message) => !message.isLastMessage
    );
    socketMessages = messagesWithoutLastSocket.slice(-1);
  }

  const transformedSocketMessages = getTransformedMessages(socketMessages);

  const transformedSocketMessage = transformedSocketMessages[0];
  const threadMessages = getTransformedMessages(
    nonSocketMessages.filter((message) => !message.isLastMessage)
  );

  const isMessageLoading = useIsMessageLoading();

  return {
    messages,
    threadMessages,
    transformedSocketMessage,
    isMessageLoading
  };
};
export default useChatMessages;
