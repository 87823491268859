import AssetHeaderWrapper from "../AssetHeaderWrapper";
import AssetNameContainer from "./AssetNameContainer";
import TextAssetIcons from "./textAssetIcons/TextAssetIcons";

function AssetHeader() {
  return (
    <AssetHeaderWrapper>
      <div className="flex justify-space-between align-i-center p-t-24px p-b-8px asset-max-width m-h-auto add-scroll-margins">
        <AssetNameContainer />
        <TextAssetIcons />
      </div>
    </AssetHeaderWrapper>
  );
}

export default AssetHeader;
