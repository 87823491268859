import { Chip, Stack } from "@mui/material";
import { FileTextFillIcon } from "../../../../../../../globalTheme/icons/icons";

export default function MessageFileChips({ files }: { files: string[] }) {
  if (!files || files.length === 0) return null;

  return (
    <Stack
      direction="column"
      spacing={1}
      alignItems="flex-end"
      className="m-b-8px"
    >
      {files?.map((file, index) => (
        <Chip
          key={index}
          icon={<FileTextFillIcon size={24} />}
          label={file}
          className="p-h-12px p-v-24px min-w-180px"
        />
      ))}
    </Stack>
  );
}
