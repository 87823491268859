import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../stores/store";

interface IAgentBuilderInitialState {
  open: boolean;
  toolsOutdated: boolean;
}

const initialState: IAgentBuilderInitialState = {
  open: false,
  toolsOutdated: false
};

export type TAgentBuilderReducerName = "agentBuilder";
export const AGENT_BUILDER_REDUCER_NAME: TAgentBuilderReducerName =
  "agentBuilder";

const agentBuilderSlice = createSlice({
  name: AGENT_BUILDER_REDUCER_NAME,
  initialState,
  reducers: {
    openAgentSaveDialog(state) {
      state.open = true;
    },
    closeAgentSaveDialog(state) {
      state.open = false;
    },
    setToolsOutdated(state, action: PayloadAction<boolean>) {
      state.toolsOutdated = action.payload;
    }
  }
});

export const selectAgentSaveDialogOpen = (state: RootState) =>
  state[AGENT_BUILDER_REDUCER_NAME].open;

export const { openAgentSaveDialog, closeAgentSaveDialog, setToolsOutdated } =
  agentBuilderSlice.actions;

export default agentBuilderSlice.reducer;
