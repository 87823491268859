import React from "react";
import useGetCurrentUser from "../user/hooks/useGetCurrentUser";

const ToolflowEmployeeGuard = ({
  component
}: {
  component: React.ReactNode;
}) => {
  const user = useGetCurrentUser();

  if (user?.toolflowEmployeeType === "employee") {
    return <>{component}</>;
  }
  return null;
};

export default ToolflowEmployeeGuard;
