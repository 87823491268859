import React, { useEffect } from "react";
import { useForm, FormProvider, type UseFormReturn } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import useDefaultAgentFormValues, {
  TAgentFormValues
} from "../hooks/useDefaultAgentFormValues";
import { visibilitySchema } from "../../../../../utilities/validation/zod/visibilitySchema";
import tagSchema from "../../../../../utilities/validation/zod/ts";
import { AnthropicModel, OpenAiTextModel } from "@toolflow/shared";
import { AGENT_NAME_REQUIRED_ERROR } from "../constants/errors";
import { ABOUT_CHARACTER_LENGTH } from "../../../../tools/components/editorToolCard/inputs/helpers/constants";

const schema = z.object({
  name: z.string().min(1, { message: AGENT_NAME_REQUIRED_ERROR }),
  description: z
    .string()
    .min(0)
    .max(ABOUT_CHARACTER_LENGTH, {
      message: `Description must be less than ${ABOUT_CHARACTER_LENGTH} characters`
    }),
  visibility: visibilitySchema,
  userInstructions: z.any(),
  tag: tagSchema,
  config: z.object({
    instructions: z.any(),
    model: z.union([
      z.nativeEnum(OpenAiTextModel),
      z.nativeEnum(AnthropicModel) // Replace YourSecondEnum with your other enum
    ]),
    temperature: z.number(),
    toolIds: z.array(z.any()),
    files: z.array(z.any())
  })
});

const useResetDefaultValues = (
  defaultValues: TAgentFormValues,
  methods: UseFormReturn<TAgentFormValues, $TSAllowedAny, undefined>
) => {
  useEffect(() => {
    if (defaultValues) {
      Object.entries(defaultValues).forEach(([name, value]) => {
        methods.register(name as keyof TAgentFormValues);
        methods.setValue(name as keyof TAgentFormValues, value, {
          shouldDirty: false,
          shouldTouch: false,
          shouldValidate: false
        });
      });
    }
  }, [JSON.stringify(defaultValues)]);
};

const AgentFormProvider = ({ children }: { children: React.ReactNode }) => {
  const defaultValues = useDefaultAgentFormValues();

  const methods = useForm<TAgentFormValues>({
    defaultValues: defaultValues,
    resolver: zodResolver(schema),
    mode: "onChange"
  });

  useResetDefaultValues(defaultValues, methods);

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default AgentFormProvider;
