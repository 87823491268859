import React, { useState } from "react";
import ToolflowApiKeys from "./ToolflowApiKeys";
import LLMKeys from "./LLMKeys";
import { Tab, Tabs } from "@mui/material";
import TabPanel from "../../../../../utilities/components/tabs/TabPanel";
import { a11yProps } from "../../../../../utilities/components/tabs/functions/a11yProps";
import FullWidthCard from "../../../../../utilities/components/cards/FullWidthCard";
import useAllowAPIKeys from "./hooks/useAllowAPIKeys";

type TTabs = "toolflowApiKeys" | "llmModelApiKeys";

type TTabValues = 0 | 1;

let tabId: TTabValues = 0;
const tabDict: {
  [key in TTabs]: {
    value: TTabValues;
    component: () => React.ReactNode;
    label: string;
  };
} = {
  toolflowApiKeys: {
    label: "Toolflow API keys",
    value: tabId++ as TTabValues,
    component: ToolflowApiKeys
  },
  llmModelApiKeys: {
    label: "LLM model API keys",
    value: tabId++ as TTabValues,
    component: LLMKeys
  }
};
const ApiKeySettings = () => {
  const [value, setValue] = useState(0);
  const isAuthorized = useAllowAPIKeys();

  const handleChange = (event: React.SyntheticEvent, newValue: TTabValues) => {
    const targetTab = Object.values(tabDict).find(
      (tab) => tab.value === newValue
    );
    if (targetTab && isAuthorized) {
      setValue(newValue);
    }
  };

  const objValues = Object.values(tabDict);
  return (
    <FullWidthCard className="p-32px m-t-32px">
      <Tabs value={value} onChange={handleChange} textColor="secondary">
        {objValues.map((tab) => {
          return (
            <Tab
              component="div"
              key={tab.value}
              label={tab.label}
              disabled={!isAuthorized}
              {...a11yProps(tab.value)}
            />
          );
        })}
      </Tabs>
      {objValues.map((tab) => (
        <TabPanel
          value={value}
          key={tab.value}
          index={tab.value}
          className="w-100-percent"
        >
          {tab.component()}
        </TabPanel>
      ))}
    </FullWidthCard>
  );
};

export default ApiKeySettings;
