import { CircularProgress, Stack, Typography } from "@mui/material";
import {
  type AgentConfigFile,
  type FileReference,
  type SelectKnowledgeFieldType
} from "@toolflow/shared";
import { useEffect } from "react";
import {
  DeleteIcon,
  FileTextFillIcon
} from "../../../../../../../../../globalTheme/icons/icons";
import RemixButton from "../../../../../../../../../utilities/components/icons/RemixButton";
import { isRawFile } from "../../../../../../../../files/fileUpload/FullScreenFileDropzone";
import useDeleteFile from "../../../../../../../../files/fileUpload/hooks/useDeleteFile";
import useFileUpload from "../../../../../../../../files/fileUpload/hooks/useFileUpload";
import { useFieldsByTypeContext } from "../../../FieldsByTypeContext";
import useFieldsByTypeHelper from "../../../useFieldsByTypeHelper";
import styles from "../selectKnowledgeField.module.css";

export type FileItem = FileReference | File;

export default function FileListItem({
  file,
  index
}: {
  file: FileItem;
  index: number;
}) {
  const { field, data, updateField } =
    useFieldsByTypeContext<SelectKnowledgeFieldType>();

  const { disabled } = field;
  const { fieldKey, acceptedExtensions } = field.config;

  const { getFieldValue } = useFieldsByTypeHelper({ settings: data });
  const files: AgentConfigFile[] = getFieldValue(fieldKey, []);

  const { tmpFile, progress, abortUpload, handleFileSelect } = useFileUpload({
    setCurrentFile: (fileRef) => {
      if (fileRef) {
        files[index].item = fileRef;
        updateField(files, fieldKey);
      }
    },
    accept: acceptedExtensions,
    disabled
  });

  const { handleDeleteFile } = useDeleteFile(() => {
    updateField(
      files.filter((_, idx) => idx !== index),
      fieldKey
    );
  });

  const handleDelete = async () => {
    if (isRawFile(file)) {
      abortUpload();
    } else {
      await handleDeleteFile(file._id);
    }
  };

  useEffect(() => {
    // Making sure file is only uploaded once on mount
    // On dev server with strict mode, on each mount react unmounts and remounts the components, essentially running useEffect twice
    const timeout = setTimeout(async () => {
      if (isRawFile(file) && !tmpFile) {
        await handleFileSelect([file]);
      }
    }, 1);
    return () => clearTimeout(timeout);
  }, [file]);

  const fileName = (file as File).name || (file as FileReference).fileName;

  return (
    <div className={styles["file-list-item"]}>
      <Stack direction="row" spacing={1} alignItems="center">
        <FileTextFillIcon size={24} />
        <Typography variant="body1">{fileName}</Typography>
      </Stack>
      <div className="pos-relative">
        <CircularProgress
          variant="determinate"
          value={progress}
          size={24}
          className={styles["file-upload-progress"]}
        />
        <RemixButton
          text="Delete"
          icon={DeleteIcon}
          onClick={handleDelete}
          className={"tag-delete-button border-radius-6px"}
        />
      </div>
    </div>
  );
}
