export const WORKFLOW_VERSION_TAG_TYPE = "WorkflowVersion";
export const API_KEY_TAG_TYPE = "APIKey";
export const CURRENT_USER_TAG_TYPE = "CurrentUser";
export const CREDITS_TAG_TYPE = "Credits";
export const SUBSCRIPTION_TAG_TYPE = "Subscription";
export const MARKETPLACE_TAG_TYPE = "MarketplaceTools";
export const FAVORITE_TAG_TYPE = "FavoriteTool";
export const TOOL_TAG_TYPE = "Tool";
export const TOOL_DETAIL_TAG_TYPE = "ToolDetail";
export const FILE_TAG_TYPE = "File";
export const SECRET_TAG_TYPE = "Secret";
export const SNIPPET_TAG_TYPE = "Snippet";
export const PROFILE_TAG_TYPE = "Profile";
export const WORKSPACE_TAG_TYPE = "Workspace";
export const WORKFLOW_TAG_TYPE = "Workflow";
export const AGENT_TAG_TYPE = "Agent";
export const TOOL_VERSION_RESPONSE_TAG_TYPE = "ToolVersionResponse";
export const THREAD_TAG_TYPE = "Thread";
export const THREAD_RUN_ID_TAG_TYPE = "ThreadRunId";
export const CAN_RUN_AGENT_TAG_TYPE = "CanRunAgent";
export const GET_AGENTS_TAG_TYPE = "GetAgents";
export const THREADS_TAG_TYPE = "Threads";
export const USER_INTEGRATION_TAG_TYPE = "UserIntegration";

export type TInvalidatedTagType =
  | typeof TOOL_TAG_TYPE
  | typeof WORKSPACE_TAG_TYPE
  | typeof WORKFLOW_TAG_TYPE
  | typeof AGENT_TAG_TYPE;

export const TAGS_ARRAY = [
  CAN_RUN_AGENT_TAG_TYPE,
  AGENT_TAG_TYPE,
  GET_AGENTS_TAG_TYPE,
  CURRENT_USER_TAG_TYPE,
  CREDITS_TAG_TYPE,
  SUBSCRIPTION_TAG_TYPE,
  API_KEY_TAG_TYPE,
  FAVORITE_TAG_TYPE,
  PROFILE_TAG_TYPE,
  MARKETPLACE_TAG_TYPE,
  WORKSPACE_TAG_TYPE,
  TOOL_TAG_TYPE,
  SECRET_TAG_TYPE,
  TOOL_DETAIL_TAG_TYPE,
  SNIPPET_TAG_TYPE,
  FILE_TAG_TYPE,
  TOOL_VERSION_RESPONSE_TAG_TYPE,
  WORKFLOW_TAG_TYPE,
  WORKFLOW_VERSION_TAG_TYPE,
  THREAD_TAG_TYPE,
  THREADS_TAG_TYPE,
  THREAD_RUN_ID_TAG_TYPE,
  THREAD_RUN_ID_TAG_TYPE,
  THREAD_RUN_ID_TAG_TYPE,
  USER_INTEGRATION_TAG_TYPE
] as const;
