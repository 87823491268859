import { useFormContext } from "react-hook-form";
import useAgentResetDefaultNeedsReset from "./useAgentResetDefaultNeedsReset";
import { TAgentFormValues } from "../../hooks/useDefaultAgentFormValues";

const useAgentReset = () => {
  const { reset } = useFormContext<TAgentFormValues>();

  return useAgentResetDefaultNeedsReset(reset);
};

export default useAgentReset;
