import React from "react";
import AlertMessageSnackbar from "./alertMessageSnackbar/AlertMessageSnackbar";
import SidebarContainer from "./sidebar/SidebarContainer";
import { useAuthStore } from "../auth/hooks/useAuthStore";
import useDuplicateObjectOnAuth from "../auth/hooks/useDuplicateObjectOnAuth";
import useRouterListener from "../navigation/hooks/useRouterListener";
import { useDocumentTitle } from "../navigation/hooks/useDocumentTitle";
import LoggedOutWrapper from "../auth/LoggedOutWrapper";
import ToolInfoDrawer from "../tools/components/toolInfoDrawer/ToolInfoDrawer";
import { AuthenticationWrapper } from "../auth/RerouteToCalendlyWrapper";
import AuthenticationLoading from "./utilities/AuthenticationLoading";
import { ImpersonationAlert } from "../pages/profile/pages/settings/impersonation/ImpersonationAlert";

function AppInner() {
  useDuplicateObjectOnAuth();
  useDocumentTitle();
  useRouterListener();

  return (
    <div className="min-h-100vh flex bg-color-app-background">
      <SidebarContainer />
      <LoggedOutWrapper />
      <AlertMessageSnackbar />
      <ToolInfoDrawer />
      <ImpersonationAlert />
    </div>
  );
}

function App() {
  const { isAuthenticated, isLoading } = useAuthStore();

  if (isLoading) {
    return <AuthenticationLoading />;
  }

  if (isAuthenticated) {
    return (
      <AuthenticationWrapper>
        <AppInner />
      </AuthenticationWrapper>
    );
  }
  return <AppInner />;
}

export default App;
