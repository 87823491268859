import type {
  GetAgentResponse,
  GetAgentsResponse,
  SaveAgentResponse,
  TAgentBuilderForm,
  TMarketplaceAgentEntity,
  TRunAgentRequest
} from "@toolflow/shared";
import { setThreadMessagesLoading } from "../../features/pages/workstation/components/chat/chatbox/chatSlice";
import { setErrorMessage, setSuccessMessage } from "../../stores/actions";
import { authenticatedApi } from "../authenticatedAPI";
import {
  AGENT_TAG_TYPE,
  CAN_RUN_AGENT_TAG_TYPE,
  GET_AGENTS_TAG_TYPE
} from "../cacheTagConstants";
import { RootState } from "../../stores/store";
import invalidateAgentTags from "./helpers/invalidateAgentTags";
import type { EntityState } from "@reduxjs/toolkit";
import { marketplaceAgentEntityAdapter } from "../../stores/adapters/marketplaceEntityAdapter";

export const agentApi = authenticatedApi.injectEndpoints({
  endpoints: (builder) => ({
    runAgent: builder.mutation<
      {
        result: { dbThreadId: string; agentId: string };
        success: boolean;
        error?: string | null;
      },
      TRunAgentRequest
    >({
      query: (agentRequest: TRunAgentRequest) => ({
        url: `runAgent`,
        method: "POST",
        body: agentRequest,
        timeout: 30000
      }),
      invalidatesTags: () => {
        return [CAN_RUN_AGENT_TAG_TYPE];
      },
      async onQueryStarted(undefined, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          if (!response.data.success) {
            dispatch(
              setErrorMessage(
                response.data.error ?? "Something went wrong running the agent"
              )
            );
            dispatch(setThreadMessagesLoading(false));
          }
        } catch (error) {
          dispatch(setErrorMessage("Something went wrong"));
          dispatch(setThreadMessagesLoading(false));
        }
      }
    }),
    canRunAgent: builder.query({
      query: () => ({
        url: `canRunAgent`,
        method: "GET",
        timeout: 30000
      }),
      providesTags: [CAN_RUN_AGENT_TAG_TYPE]
    }),
    getProfileAgents: builder.query<
      EntityState<TMarketplaceAgentEntity, string>,
      string
    >({
      query: (profileId: string) => ({
        url: `agent/p/${profileId}`,
        method: "GET"
      }),
      transformResponse: (response: GetAgentsResponse) => {
        return marketplaceAgentEntityAdapter.upsertMany(
          marketplaceAgentEntityAdapter.getInitialState(),
          response.result.agents
        );
      },
      providesTags: [GET_AGENTS_TAG_TYPE]
    }),
    getAgent: builder.query<GetAgentResponse, string>({
      query: (agentId: string) => ({
        url: `agents/${agentId}`,
        method: "GET"
      }),
      providesTags: (result, error, agentId) => {
        return [{ type: AGENT_TAG_TYPE, id: agentId }];
      }
    }),
    saveAgent: builder.mutation<
      SaveAgentResponse,
      {
        agentId?: string;
        body: { agent: TAgentBuilderForm };
      }
    >({
      query: ({ body, agentId = "" }) => ({
        url: `agents/${agentId}`,
        method: "POST",
        body
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;
          invalidateAgentTags(
            dispatch,
            getState() as RootState,
            params.agentId
          );
          dispatch(setSuccessMessage("Agent saved"));
        } catch (error: $TSAllowedAny) {
          dispatch(
            setErrorMessage(error?.error?.data?.error || "Error saving agent")
          );
        }
      },
      invalidatesTags: (result, error, args) => [
        GET_AGENTS_TAG_TYPE,
        { type: AGENT_TAG_TYPE, id: args.agentId }
      ]
    }),
    deleteAgent: builder.mutation({
      query: (agentId: string) => ({
        url: `agents/${agentId}`,
        method: "DELETE"
      }),
      async onQueryStarted(agentId, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;
          invalidateAgentTags(dispatch, getState() as RootState, agentId);
          dispatch(setSuccessMessage("Agent deleted"));
        } catch (error: $TSAllowedAny) {
          dispatch(
            setErrorMessage(error?.error?.data?.error || "Error deleting agent")
          );
        }
      },
      invalidatesTags: (result, error, args) => [
        GET_AGENTS_TAG_TYPE,
        { type: AGENT_TAG_TYPE, id: args }
      ]
    }),
    updateAgent: builder.mutation<
      SaveAgentResponse,
      {
        agentId?: string;
        body: { agent: Partial<TAgentBuilderForm> };
      }
    >({
      query: ({ agentId = "", body }) => ({
        url: `agents/${agentId}`,
        method: "POST",
        body
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;
          invalidateAgentTags(
            dispatch,
            getState() as RootState,
            params.agentId
          );
          dispatch(setSuccessMessage("Agent saved"));
        } catch {
          dispatch(setErrorMessage("Error updating agent"));
        }
      },
      invalidatesTags: (result, error, args) => [
        GET_AGENTS_TAG_TYPE,
        { type: AGENT_TAG_TYPE, id: args.agentId }
      ]
    })
  })
});

export const {
  useRunAgentMutation,
  useCanRunAgentQuery,
  useGetProfileAgentsQuery,
  useGetAgentQuery,
  useSaveAgentMutation,
  useUpdateAgentMutation,
  useDeleteAgentMutation
} = agentApi;
