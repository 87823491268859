import { JSONContent } from "@tiptap/core";
import { HydratedDocument } from "mongoose";
import { AnthropicModel } from "../LLMModels";
import { AgentSchemaId, UserSchemaId } from "../schemaTypes";
import { FileReference } from "./FileReferenceDocument";
import { LeanDocument, TTagObject, VisibilityTypes } from "./utilities";
import { Snippet } from "./SnippetDocument";

export enum EAgentType {
  OpenAIAssistant = "OpenAIAssistant",
  AnthropicAssistant = "AnthropicAssistant"
}

export const SaveableMessageTypeArray: readonly EAgentType[] = [
  EAgentType.AnthropicAssistant
] as const;

export type TInstructions = JSONContent | string;

export type TAgentDataToSave = {
  name: string;
  description: string;
  visibility: VisibilityTypes;
  userInstructions: TInstructions;
  tag: TTagObject;
  isFeatured: boolean;
  isPopular: boolean;
  userId: string;
  externalId?: string;
  type: EAgentType;
  enableForking: boolean;
  config: TUniversalAgentConfig;
};

export function isAgentDataToSave(obj: unknown): obj is TAgentDataToSave {
  if (!obj || typeof obj !== "object") return false;

  const data = obj as Record<string, unknown>;

  return (
    typeof data.name === "string" &&
    typeof data.description === "string" &&
    typeof data.visibility === "string" &&
    typeof data.tag === "object" &&
    data.tag !== null &&
    typeof data.config === "object" &&
    data.config !== null &&
    typeof (data.config as TUniversalAgentConfig).model === "string" &&
    typeof (data.config as TUniversalAgentConfig).temperature === "number" &&
    Array.isArray((data.config as TUniversalAgentConfig).toolIds)
  );
}

export type TAgentDataToRepoSave =
  | (Omit<TAgentDataToSave, "config" | "type" | "externalId"> & {
      type: EAgentType.OpenAIAssistant;
      externalId: string;
      config: OpenAIAssistantConfig;
    })
  | (Omit<TAgentDataToSave, "config" | "type" | "externalId"> & {
      type: EAgentType.AnthropicAssistant;
      config: AnthropicAssistantConfig;
    });

export type TUniversalAgent = TAgentDataToSave & {
  _id: string;
  createdAt: number;
  updatedAt: number;
};

export type TUniversalAgentConfigBase = {
  instructions: TInstructions;
  files?: AgentConfigFile[];
};

export type OpenAIAssistantConfig = TUniversalAgentConfigBase;
export type AnthropicAssistantConfig = Omit<TUniversalAgentConfig, "model"> & {
  model: AnthropicModel;
};

export type TUniversalAgentConfig = TUniversalAgentConfigBase & {
  model: string;
  temperature: number;
  toolIds: string[];
};

export enum AgentConfigFileType {
  Uploaded = "uploaded",
  Knowledge = "knowledge"
}

export type AgentConfigFile =
  | AgentConfigUploadedFile
  | AgentConfigKnowledgeFile;

export interface AgentConfigUploadedFile {
  type: AgentConfigFileType.Uploaded;
  item: FileReference;
}

export interface AgentConfigKnowledgeFile {
  type: AgentConfigFileType.Knowledge;
  item: Snippet;
}

export type AgentConfigMap = {
  [EAgentType.OpenAIAssistant]: OpenAIAssistantConfig;
  [EAgentType.AnthropicAssistant]: AnthropicAssistantConfig;
};

export type ExternalIdMap = {
  [EAgentType.OpenAIAssistant]: string;
  [EAgentType.AnthropicAssistant]: undefined;
};

type PropsWithSchemaId<T extends EAgentType> = {
  _id: AgentSchemaId;
  userId?: UserSchemaId;
  type: T;
  config: AgentConfigMap[T];
  externalId: ExternalIdMap[T];
};

export type TAgentReferenceDocument =
  | HydratedDocument<
      TUniversalAgent,
      PropsWithSchemaId<EAgentType.AnthropicAssistant>
    >
  | HydratedDocument<
      TUniversalAgent,
      PropsWithSchemaId<EAgentType.OpenAIAssistant>
    >;

export type LeanAgentReferenceDocumentAnthropic = LeanDocument<
  TUniversalAgent,
  PropsWithSchemaId<EAgentType.AnthropicAssistant>
>;

export type LeanAgentReferenceDocumentOpenAI = LeanDocument<
  TUniversalAgent,
  PropsWithSchemaId<EAgentType.OpenAIAssistant>
>;

export type LeanAgentReferenceDocument =
  | LeanAgentReferenceDocumentAnthropic
  | LeanAgentReferenceDocumentOpenAI;
