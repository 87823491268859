import { DeleteIcon } from "../../../../../../globalTheme/icons/icons";
import { toolflowTextDisabledColor } from "../../../../../../globalTheme/muiUtils/appTheme";
import SettingsMenuItem from "../../../../../../utilities/components/dropdowns/generic/SettingsMenuItem";
import RemixWrapperIconWithTheme from "../../../../../../utilities/components/icons/RemixWrapperIconWithTheme";
import useDeleteAsset from "../../../assets/useDeleteAsset";
import useGetCurrentAsset from "../../../assets/useGetCurrentAsset";

const DeleteAssetMenuItem = () => {
  const input = useGetCurrentAsset();
  const deleteAsset = useDeleteAsset();
  if (!input) {
    return null;
  }

  const handleDelete = () => {
    deleteAsset(input.id);
  };

  return (
    <SettingsMenuItem
      text={`Delete`}
      action={handleDelete}
      icon={
        <RemixWrapperIconWithTheme
          size={16}
          Icon={DeleteIcon}
          providedColor={toolflowTextDisabledColor}
        />
      }
    />
  );
};

export default DeleteAssetMenuItem;
