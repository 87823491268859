import useInputChipsToUse from "../../../../../../../../utilities/components/textFields/tipTapTextField/hooks/useInputChipsToUse";
import { useAgentContext } from "../../../../../../../agents/contexts/AgentContext";
import useChatText from "../../ChatInputPaper/hooks/useChatText";
import AbortChatMessageButton from "../abortChatMessageButton/AbortChatMessageButton";
import SendChatMessageButton from "../runButton/SendChatMessageButton";
import useHandleChatMessageChange from "./useHandleChatMessageChange";
import useHandleChatMessageKeyDown from "./useHandleChatMessageKeyDown";
import useIsMessageLoading from "./useIsMessageLoading";

const useSendThreadTextField = () => {
  const { userTextMessage, setUserTextMessage } = useChatText();
  const handleKeyDown = useHandleChatMessageKeyDown(setUserTextMessage);
  const handleChange = useHandleChatMessageChange(setUserTextMessage);
  const isMessageLoading = useIsMessageLoading();
  const chipsToUse = useInputChipsToUse();
  const { agent } = useAgentContext();

  return {
    userTextMessage,
    handleChange,
    handleKeyDown,
    setUserTextMessage,
    isMessageLoading,
    chipsToUse,
    agentId: agent._id,
    placeholderText: `Chat with ${agent.name}`,
    additionalActions: isMessageLoading ? (
      <AbortChatMessageButton />
    ) : (
      <SendChatMessageButton
        userTextMessage={userTextMessage}
        setUserTextMessage={setUserTextMessage}
      />
    )
  };
};

export default useSendThreadTextField;
