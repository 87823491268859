import type { Asset } from "@toolflow/shared";
import { useWorkspaceAssetsFieldArrayContext } from "../contexts/WorkspaceAssetsContext";
import useWorkspaceAssets from "./useWorkspaceAssets";

const useHandleAssetNameChange = ({ asset }: { asset: Asset }) => {
  const assets = useWorkspaceAssets();
  const { update } = useWorkspaceAssetsFieldArrayContext();
  const handleNameChange = (s: string) => {
    assets.map((i, index) => {
      if (i.id === asset.id) {
        update(index, {
          ...i,
          name: s,
          label: s
        });
      }
    });
  };
  return handleNameChange;
};

export default useHandleAssetNameChange;
