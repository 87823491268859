import { EditIcon } from "../../../../../../globalTheme/icons/icons";
import { useToolContext } from "../../../../../tools/contexts/ToolContext";
import { useTVRContext } from "../../../../../toolVersionResponse/contexts/ToolVersionResponseContext";
import ToolVersionResponseContextContainer from "../../../../../toolVersionResponse/contexts/ToolVersionResponseContextContainer";
import { GroupAccordionHeader } from "../../../../builder/toolBuilder/common/fieldsByType/fieldsGroupComponent/GroupAccordionHeader";
import useSetSelectedTool from "../../../hooks/useSetSelectedTool";

const ToolRunByUserInner = () => {
  const { tvr } = useTVRContext();
  const { tool } = useToolContext();
  const setSelectedTool = useSetSelectedTool();

  const handleClick = () => {
    setSelectedTool(tool._id.toString(), tvr.validatedUserInput);
  };

  return (
    <GroupAccordionHeader
      className={"background-color-hover cursor-pointer m-0px"}
      disableMargin={true}
      title={tool.name}
      onClick={handleClick}
      titleVariant="subtitle2"
      titleClassName="grey"
      actionIcon={<EditIcon size={14} className="grey" />}
    />
  );
};

const ToolRunByUser = ({ tvrId }: { tvrId?: string }) => {
  return (
    <ToolVersionResponseContextContainer tvrId={tvrId}>
      <ToolRunByUserInner />
    </ToolVersionResponseContextContainer>
  );
};

export default ToolRunByUser;
