export enum OpenAiTextModel {
  GPT35 = "gpt-3.5-turbo",
  GPT4 = "gpt-4",
  GPT4Turbo = "gpt-4-turbo-preview",
  GPT4o = "gpt-4o",
  GPT4o_Aug = "gpt-4o-2024-08-06"
}

export enum OpenAiEmbeddingModel {
  SMALL_3 = "text-embedding-3-small",
  LARGE_3 = "text-embedding-3-large",
  ADA_002 = "text-embedding-ada-002"
}

export enum OpenAiImageModel {
  DALLE2 = "dall-e-2",
  DALLE3 = "dall-e-3"
}

export type OpenAiModel = OpenAiTextModel | OpenAiImageModel;

export enum AnthropicModel {
  OPUS = "claude-3-opus-20240229",
  SONNET = "claude-3-sonnet-20240229",
  SONNET_3_5 = "claude-3-5-sonnet-20240620",
  HAIKU = "claude-3-haiku-20240307"
}

export enum PerplexityModel {
  SonarSmOnline = "llama-3.1-sonar-small-128k-online",
  SonarLgOnline = "llama-3.1-sonar-large-128k-online",
  SonarHgOnline = "llama-3.1-sonar-huge-128k-online",
  SonarSmChat = "llama-3.1-sonar-small-128k-chat",
  SonarLgChat = "llama-3.1-sonar-large-128k-chat"
}

export enum DeepgramModel {
  Nova2 = "nova-2",
  Nova = "nova",
  Enhanced = "enhanced",
  Base = "base",
  WhisperMedium = "whisper-medium"
}

export enum EmbeddingServiceType {
  OpenAi = "openAI"
}

export enum ChatLLMType {
  OpenAi = "openai",
  Anthropic = "anthropic"
}

export type TextPromptModels =
  | OpenAiTextModel
  | OpenAiImageModel
  | PerplexityModel;
