import { useMemo, useEffect } from "react";
import { useGetAllUserWorkflowsAsEntities } from "./useGetAllUserWorkflowsAsEntities";
import { useGetCurrentUserTools } from "../../tools/hooks/useGetCurrentUserTools";
import { useDispatch } from "react-redux";
import { setRerouteToMarketplace } from "../../tools/components/searchToolsContainer/slice/searchToolsSlice";

export const useGetAllUserEntities = () => {
  const { entities: workflowEntities, loading: workflowsLoading } =
    useGetAllUserWorkflowsAsEntities();
  const { tools: toolEntities, loading: toolsLoading } =
    useGetCurrentUserTools();

  const loading = toolsLoading || workflowsLoading;

  return {
    entities: loading ? [] : [...workflowEntities, ...toolEntities],
    loading
  };
};

const useRerouteToMarketplace = () => {
  const dispatch = useDispatch();
  const { entities } = useGetAllUserEntities();

  useEffect(() => {
    if (entities?.length === 0) {
      dispatch(setRerouteToMarketplace());
    }
  }, [JSON.stringify(entities)]);
};

export const useHandleUserEntities = () => {
  const { entities } = useGetAllUserEntities();
  useRerouteToMarketplace();

  return useMemo(() => entities, [entities]);
};
