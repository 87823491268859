import {
  type TOpenAIPriceDictType,
  type TAnthropicPriceDictType,
  type TPerplexityPriceDictType,
  type SelectOption,
  anthropicPriceDict,
  perplexityPriceDict,
  openAIPriceDict,
  AnthropicModel,
  OpenAiTextModel,
  PerplexityModel
} from "@toolflow/shared";
import formatNumberToLocale from "../../../../../utilities/formatters/numbers/formatNumberToLocale";

export function getBaseModelOptions(
  priceDict:
    | TOpenAIPriceDictType
    | TAnthropicPriceDictType
    | TPerplexityPriceDictType,
  modelFilter?: string[]
) {
  return Object.entries(priceDict).reduce((acc, [k, v]) => {
    if (!modelFilter || modelFilter.includes(k)) {
      acc.push({
        value: k,
        label: v.label,
        subText: `${formatNumberToLocale(v.tokenCount)} tokens`
      });
    }
    return acc;
  }, [] as SelectOption[]);
}

export function getTextGenerationTechOptions(modelFilter?: string[]) {
  return [
    ...getBaseModelOptions(anthropicPriceDict, modelFilter),
    ...getBaseModelOptions(openAIPriceDict, modelFilter)
  ];
}

export function getAnthropicModelOptions() {
  return getBaseModelOptions(anthropicPriceDict);
}

export function getPerplexityModelOptions() {
  return getBaseModelOptions(perplexityPriceDict, [
    PerplexityModel.SonarSmOnline,
    PerplexityModel.SonarLgOnline,
    PerplexityModel.SonarHgOnline
  ]);
}

export function getOpenAIModelOptions() {
  return getBaseModelOptions(openAIPriceDict);
}

export function getAgentModelOptions() {
  return getTextGenerationTechOptions([
    AnthropicModel.SONNET_3_5,
    OpenAiTextModel.GPT4o
  ]);
}
