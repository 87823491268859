import { useMemo } from "react";
import {
  DEFAULT_TAG,
  OpenAiTextModel,
  type TInstructions,
  type TTagObject,
  type TUniversalAgent,
  VisibilityTypes
} from "@toolflow/shared";
import useGetAgentByParams from "./useGetAgentByParams";
import {
  AGENT_DESCRIPTION_FIELD_LABEL,
  AGENT_INSTRUCTIONS_FIELD_LABEL,
  AGENT_MODEL_FIELD_LABEL,
  AGENT_NAME_FIELD_LABEL,
  AGENT_TAG_FIELD_LABEL,
  AGENT_TEMPERATURE_FIELD_LABEL,
  AGENT_TOOLS_FIELD_LABEL,
  AGENT_VISIBILITY_FIELD_LABEL,
  AGENT_ENABLE_FORKING_FIELD_LABEL,
  AGENT_FILES_FIELD_LABEL,
  AGENT_USER_INSTRUCTIONS_FIELD_LABEL
} from "../constants/fields";
import { DEFAULT_AGENT_NAME } from "../constants/defaults";

export type TAgentFormValues = {
  config: {
    [AGENT_INSTRUCTIONS_FIELD_LABEL]: TInstructions;
    [AGENT_MODEL_FIELD_LABEL]: string;
    [AGENT_TEMPERATURE_FIELD_LABEL]: number;
    [AGENT_TOOLS_FIELD_LABEL]: Array<unknown>;
    [AGENT_FILES_FIELD_LABEL]: Array<unknown>;
  };
  [AGENT_TAG_FIELD_LABEL]: TTagObject;
  [AGENT_NAME_FIELD_LABEL]: string;
  [AGENT_DESCRIPTION_FIELD_LABEL]: string;
  [AGENT_VISIBILITY_FIELD_LABEL]: VisibilityTypes;
  [AGENT_ENABLE_FORKING_FIELD_LABEL]: boolean;
  [AGENT_USER_INSTRUCTIONS_FIELD_LABEL]: TInstructions;
};

const getDefaultAgentFormValues = (
  agent?: TUniversalAgent
): TAgentFormValues => {
  return {
    config: {
      [AGENT_INSTRUCTIONS_FIELD_LABEL]:
        agent?.config[AGENT_INSTRUCTIONS_FIELD_LABEL] || {},
      [AGENT_MODEL_FIELD_LABEL]:
        agent?.config[AGENT_MODEL_FIELD_LABEL] || OpenAiTextModel.GPT4o,
      [AGENT_TEMPERATURE_FIELD_LABEL]:
        agent?.config[AGENT_TEMPERATURE_FIELD_LABEL] || 50,
      [AGENT_TOOLS_FIELD_LABEL]: agent?.config[AGENT_TOOLS_FIELD_LABEL] || [],
      [AGENT_FILES_FIELD_LABEL]: agent?.config[AGENT_FILES_FIELD_LABEL] || []
    },
    [AGENT_TAG_FIELD_LABEL]: agent?.[AGENT_TAG_FIELD_LABEL] || DEFAULT_TAG,
    [AGENT_NAME_FIELD_LABEL]:
      agent?.[AGENT_NAME_FIELD_LABEL] || DEFAULT_AGENT_NAME,
    [AGENT_DESCRIPTION_FIELD_LABEL]:
      agent?.[AGENT_DESCRIPTION_FIELD_LABEL] || "",
    [AGENT_VISIBILITY_FIELD_LABEL]:
      agent?.[AGENT_VISIBILITY_FIELD_LABEL] || VisibilityTypes.PUBLIC,
    [AGENT_ENABLE_FORKING_FIELD_LABEL]:
      agent?.[AGENT_ENABLE_FORKING_FIELD_LABEL] || false,
    [AGENT_USER_INSTRUCTIONS_FIELD_LABEL]:
      agent?.[AGENT_USER_INSTRUCTIONS_FIELD_LABEL] || {}
  };
};

const useDefaultAgentFormValues = () => {
  const { data } = useGetAgentByParams();
  return useMemo(
    () => getDefaultAgentFormValues(data?.result.agent),
    [JSON.stringify(data?.result.agent)]
  );
};

export default useDefaultAgentFormValues;
