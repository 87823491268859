import { Box } from "@mui/material";
import type { AssetBaseIdsBySourceIdentifier } from "@toolflow/shared";
import React from "react";
import CondenseAssetListButton from "./CondenseAssetListButton";
import AssetGroup from "./assetGroup/AssetGroup";
import styles from "./expandedAssetList.module.css";

import { parsedClassNames } from "../../../../../../../utilities/functions/parsedClassNames";
import AddAssetButton from "./AddAssetButton";
import useTransformWorkspaceAsset from "../../../../assets/useTransformWorkspaceAsset";
import CloseAssetPanelButton from "../../CloseAssetPanelButton";
import { RootState } from "../../../../../../../stores/store";
import { useSelector } from "react-redux";

const cx = parsedClassNames.bind(styles);

function AssetList({
  transformedAssets: transformedWorkflowInputs
}: {
  transformedAssets: AssetBaseIdsBySourceIdentifier;
}) {
  return (
    <div
      className={cx(
        "scrollable-content",
        styles["asset-padding"],
        styles["list-height"]
      )}
    >
      {Object.entries(transformedWorkflowInputs).map(
        ([sourceIdentifier, objectValue]) => (
          <AssetGroup key={sourceIdentifier} objectValue={objectValue} />
        )
      )}
    </div>
  );
}

function AssetListContainer() {
  const transformedAssets = useTransformWorkspaceAsset();
  return <AssetList transformedAssets={transformedAssets} />;
}

function ExpandedAssetList() {
  const open = useSelector((state: RootState) => state.workspace.assetExpanded);
  return (
    <div className="pos-relative flex h-100-percent">
      {!open && <CloseAssetPanelButton className="m-r-12px" />}
      <Box
        className={cx(
          styles["expanded-list-outer"],
          "asset-toc-box-shadow pos-absolute"
        )}
        style={{
          top: 32,
          right: 0,
          bottom: 12
        }}
      >
        <div
          className={cx(
            "align-i-center flex m-b-12px justify-space-between",
            styles["asset-padding"]
          )}
        >
          <AddAssetButton />
          <CondenseAssetListButton />
        </div>
        <AssetListContainer />
      </Box>
    </div>
  );
}

export default ExpandedAssetList;
