import React, { FC } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import styles from "./integrationCard.module.css";
import { parsedClassNames } from "../../../../../../../utilities/functions/parsedClassNames";
import { Button, CardActions } from "@mui/material";
import { type Integration } from "../../useIntegrationMetadata";
import {
  useConnectIntegrationMutation,
  useGenerateTokenMutation,
  useGetIntegrationsQuery
} from "../../../../../../../ToolflowAPI/rtkRoutes/UserIntegrationsApi";
import { createFrontendClient } from "@pipedream/sdk";

const cx = parsedClassNames.bind(styles);

interface UserIntegrationCardProps {
  integration: Integration;
  className?: string;
}

const UserIntegrationCard: FC<UserIntegrationCardProps> = ({
  integration,
  className
}) => {
  const { type: id, name, description, icon } = integration;
  const [connectIntegration] = useConnectIntegrationMutation();
  const [generateToken] = useGenerateTokenMutation();
  const { data: integrationsData } = useGetIntegrationsQuery();
  const pd = createFrontendClient();

  const isConnected = integrationsData?.integrations?.some(
    (connectedIntegration) => connectedIntegration.integrationType === id
  );

  const handleConnectIntegration = async () => {
    try {
      const tokenResponse = await generateToken().unwrap();
      if (tokenResponse.token) {
        pd.connectAccount({
          app: id,
          token: tokenResponse.token,
          onSuccess: async ({ id: accountId }) => {
            try {
              await connectIntegration({
                integrationType: id,
                accountId
              }).unwrap();
            } catch (error) {
              console.error("Error saving integration:", error);
            }
          }
        });
      }
    } catch (error) {
      console.error("Error connecting integration:", error);
    }
  };

  return (
    <Card className={cx(styles["integration-card"], "parent-hover", className)}>
      <CardHeader
        className={cx(styles["integration-card-header"])}
        avatar={
          typeof icon === "string" ? (
            <img src={icon} width={20} height={20} />
          ) : (
            icon
          )
        }
        title={<Typography variant="h6">{name}</Typography>}
      />
      <CardContent className="p-b-0px p-t-8px pos-relative">
        <Typography
          variant="body2"
          color="text.secondary"
          paragraph
          className="m-0px oflow-two-lines h-40px"
        >
          {description}
        </Typography>
        <CardActions>
          {isConnected && (
            <Typography
              className="m-l-auto"
              variant="caption"
              color="text.secondary"
            >
              {"Connected"}
            </Typography>
          )}
          <Button
            className="m-l-auto"
            variant={isConnected ? "outlined" : "contained"}
            onClick={handleConnectIntegration}
          >
            {isConnected ? "Manage" : "Connect"}
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
};

export default UserIntegrationCard;
