import { Controller } from "react-hook-form";
import { FormControl } from "@mui/material";
import { ENABLE_FORKING_FIELD_LABEL } from "../../../../features/pages/builder/workflowBuilder/WorkflowBuilderConstants";
import { Switch } from "../../../../features/pages/builder/toolBuilder/builderDrawer/components/drawerContent/outputs/outputFields/Switch";

function UpdateEnableForkingForm({
  margin = "normal"
}: {
  margin: "none" | "normal";
}) {
  return (
    <FormControl margin={margin} size="small">
      <Controller
        render={({ field }) => (
          <Switch
            {...field}
            label="Enable users to copy template"
            checked={field.value}
          />
        )}
        name={ENABLE_FORKING_FIELD_LABEL}
      />
    </FormControl>
  );
}

export default UpdateEnableForkingForm;
