import { Paper } from "@mui/material";
import ChatFilesList from "../ChatFilesList/ChatFilesList";
import SendThreadTextField from "../SendThreadTextField/SendThreadTextField";
import ChatBottomButton from "./ChatBottomButton";
import styles from "./chatInputPaper.module.css";
import ChatProviders from "./ChatProviders";
import ChatTips from "./ChatTips";

function ChatInputPaper() {
  return (
    <ChatProviders>
      <div className="pos-relative">
        <Paper
          variant="workspaceWrapper"
          className={styles["custom-elevation"]}
          sx={{ backgroundColor: (theme) => theme.palette.background.paper }}
        >
          <ChatFilesList />
          <ChatTips />
          <SendThreadTextField />
          <ChatBottomButton className={"m-h-16px m-b-8px m-t-8px"} />
        </Paper>
        <div className={styles["border-overlay"]} />
      </div>
    </ChatProviders>
  );
}

export default ChatInputPaper;
