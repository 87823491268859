import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useFormContext } from "react-hook-form";
import { FormHelperText } from "@mui/material";

function InnerErrorContainer({ fieldName }: Readonly<{ fieldName: string }>) {
  return (
    <ErrorMessage name={fieldName} render={({ message }) => <>{message}</>} />
  );
}

function ErrorContainer({
  fieldName,
  withForm
}: Readonly<{ className?: string; fieldName: string; withForm?: boolean }>) {
  const {
    formState: { errors }
  } = useFormContext();

  const hasError = !!errors[fieldName];

  if (withForm) {
    return (
      <>
        {hasError ? (
          <FormHelperText error>
            <InnerErrorContainer fieldName={fieldName} />
          </FormHelperText>
        ) : null}
      </>
    );
  }

  return <InnerErrorContainer fieldName={fieldName} />;
}

export default ErrorContainer;
