import { useAssetContext } from "../../../../../../../utilities/contexts/AssetContext";
import AssetName from "../AssetName";

function AssetNameContainer() {
  const { asset } = useAssetContext();

  return <AssetName text={asset.name} />;
}

export default AssetNameContainer;
