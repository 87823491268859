import { type UseFormReset } from "react-hook-form";
import useDefaultAgentFormValues, {
  TAgentFormValues
} from "../../hooks/useDefaultAgentFormValues";

const useAgentResetDefaultNeedsReset = (
  reset: UseFormReset<TAgentFormValues>
) => {
  const defaultValues = useDefaultAgentFormValues();
  const agentResetDefault = () => {
    reset(defaultValues);
  };

  return agentResetDefault;
};

export default useAgentResetDefaultNeedsReset;
