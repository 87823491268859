import { FC } from "react";
import { GenericRichContent } from "../../../../../../utilities/components/textFields/workspace/GenericRichContent";
import styles from "./agentMessage.module.css";
import threadStyles from "./threadMessage.module.css";
import ThreadActions from "./threadActions/ThreadActions";
import { parsedClassNames } from "../../../../../../utilities/functions/parsedClassNames";
import type { Content } from "@tiptap/core";
interface IAgentMessageProps {
  content: Content;
}

const cx = parsedClassNames.bind(threadStyles);

const AgentMessage: FC<IAgentMessageProps> = (props) => {
  const { content } = props;
  return (
    <div className="w-fit-content pos-relative parent-hover">
      <GenericRichContent
        content={content}
        className={styles["agent-message"]}
        key="agent-message"
      />
      <ThreadActions
        content={content}
        className={cx(
          "pos-absolute show-on-hover",
          threadStyles["thread-actions"]
        )}
      />
    </div>
  );
};

export default AgentMessage;
