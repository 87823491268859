import type { JSONContent } from "@tiptap/core";
import type { TextInputContainerProps, TAddChipConfig } from "@toolflow/shared";
import { useMemo } from "react";
import { TipTapTextFieldContainer } from "../../../../../../../../utilities/components/textFields/tipTapTextField/TipTapTextField";
import { parsedClassNames } from "../../../../../../../../utilities/functions/parsedClassNames";
import useGetDrawerNodeId from "../../../../store/hooks/useGetDrawerNodeId";
import useFieldsByTypeHelper from "../../useFieldsByTypeHelper";
import InputLabelWrapper from "../helpers/InputLabelWrapper";

import styles from "./textInputContainer.module.css";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";

const cx = parsedClassNames.bind(styles);

export const textInputContainerExtensionsConfig = {
  disableFormattingExtensions: true,
  disableDefaultEnter: false
};

interface TextInputProps {
  subtitle?: string;
  autofocus?: boolean;
  chipsToUse?: TAddChipConfig;
  id?: string;
  handleTipTapChange: (value: string | JSONContent) => void;
  placeholder?: string;
  initialValue: string;
  label: string;
  className?: string;
  textFieldClassName?: string;
  disableFormattingExtensions?: boolean;
  extensionsConfig?: {
    disableFormattingExtensions?: boolean;
    disableDefaultEnter?: boolean;
    enableAutolinkExtension?: boolean;
  };
}
export function TextInput({
  subtitle,
  autofocus,
  chipsToUse,
  id,
  handleTipTapChange,
  initialValue,
  placeholder,
  label,
  className = "",
  textFieldClassName = "",
  extensionsConfig = {},
  disableFormattingExtensions
}: TextInputProps) {
  return (
    <InputLabelWrapper label={label} subtitle={subtitle} className={className}>
      <div className={"flex w-100-percent"}>
        <TipTapTextFieldContainer
          autoFocus={autofocus}
          chipsToUse={chipsToUse}
          id={id}
          extensionsConfig={{
            ...textInputContainerExtensionsConfig,
            ...extensionsConfig
          }}
          disableFormattingMenu={!!(disableFormattingExtensions ?? true)}
          saveWorkspaceType="json"
          initialValue={initialValue}
          onChange={handleTipTapChange}
          disableWhite
          label={placeholder}
          outerClassName="w-100-percent"
          className={cx("min-w-300px", textFieldClassName)}
          workspaceWrapperClassName={cx(styles["large-height"])}
        />
      </div>
    </InputLabelWrapper>
  );
}

export default function TextInputContainer({
  id,
  settings,
  field
}: TextInputContainerProps) {
  const { updateField } = useFieldsByTypeContext();
  const drawerNodeId = useGetDrawerNodeId();
  const { label, subtitle } = field;
  const {
    autofocus,
    placeholder,
    fieldKey,
    chipsToUse,
    className,
    textFieldClassName,
    enableFormatting
  } = field.config;
  const { getFieldValue } = useFieldsByTypeHelper({ settings });

  const handleTipTapChange = (value: string | JSONContent) => {
    updateField(value, fieldKey);
  };

  const initialValue = useMemo(
    () => getFieldValue(fieldKey, ""),
    [fieldKey, drawerNodeId]
  );

  const extensionsConfig = enableFormatting
    ? {
        disableFormattingExtensions: false,
        enableAutolinkExtension: true
      }
    : undefined;

  return (
    <TextInput
      subtitle={subtitle}
      autofocus={autofocus}
      chipsToUse={chipsToUse}
      handleTipTapChange={handleTipTapChange}
      className={className}
      textFieldClassName={textFieldClassName}
      placeholder={placeholder}
      label={label}
      initialValue={initialValue}
      extensionsConfig={extensionsConfig}
      disableFormattingExtensions={!enableFormatting}
      id={id}
    />
  );
}
