import { AnthropicModel, ChatLLMType, OpenAiTextModel } from "@toolflow/shared";
import { useAgentContext } from "../../../../../../../agents/contexts/AgentContext";

export default function useGetAgentModelType() {
  const { agent } = useAgentContext();

  if (Object.values(AnthropicModel).some((m) => m === agent.config.model)) {
    return ChatLLMType.Anthropic;
  } else if (
    Object.values(OpenAiTextModel).some((m) => m === agent.config.model)
  ) {
    return ChatLLMType.OpenAi;
  }
}
