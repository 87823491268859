import { type JSONContent } from "@tiptap/core";
import { Editor } from "@tiptap/react";
import type {
  AssetNodeTypes,
  SaveWorkspaceType,
  TExtensionsConfig,
  TAddChipConfig
} from "@toolflow/shared";
import React from "react";
import AddChipButton from "../../../../features/pages/workstation/components/chat/chatbox/SendThreadTextField/addChipButton/AddChipButton";
import { parsedClassNames } from "../../../functions/parsedClassNames";
import useHandleAddChipContainer from "../workspace/functions/handleAddChip";
import Workspace from "../workspace/Workspace";
import useChipsToUse from "./hooks/useChipsToUse";
import useHandleTextFieldKeyDown from "./hooks/useHandleTextFieldKeyDown";
import styles from "./tipTapTextField.module.css";

const cx = parsedClassNames.bind(styles);

interface ITipTapTextField {
  outerClassName?: string;
  container?: React.RefObject<HTMLDivElement>;
  initialValue?: string | JSONContent;
  onChange: (s: string | JSONContent) => void;
  helperText?: string;
  autoFocus?: boolean;
  id?: string;
  disableFormattingMenu?: boolean;
  label?: string;
  verticalLabel?: boolean;
  variant?: "standard" | "outlined";
  richTextFieldClassName?: string;
  assetNodeType?: AssetNodeTypes;
  saveWorkspaceType?: SaveWorkspaceType;
  className?: string;
  disableWhite?: boolean;
  maxHeight?: string | number;
  enableHover?: boolean;
  disableBubble?: boolean;
  overrideKeydown?: (event: KeyboardEvent, e?: Editor) => void;
  additionalActions?: React.ReactNode;
  disableOutlineHover?: boolean;
  chipsToUse?: TAddChipConfig;
  extensionsConfig?: TExtensionsConfig;
  workspaceWrapperClassName?: string;
}

const TipTapTextField = ({
  outerClassName,
  container,
  initialValue = "", // tiptap can't be controlled, so we just initialize it and use onUpdate to track the changes elsewhere
  onChange,
  autoFocus,
  id = "",
  chipsToUse,
  label,
  variant = "outlined",
  richTextFieldClassName = "tiptap-textfield-p",
  assetNodeType = "chip",
  saveWorkspaceType = "text",
  className = "",
  disableWhite,
  maxHeight,
  overrideKeydown,
  disableFormattingMenu,
  enableHover = true,
  extensionsConfig,
  disableBubble,
  additionalActions,
  disableOutlineHover,
  workspaceWrapperClassName = ""
}: ITipTapTextField) => {
  const { ref, handleAddChip } = useHandleAddChipContainer();
  const chipsWithSnippets = useChipsToUse(chipsToUse);

  return (
    <div
      className={cx(
        "flex p-0px bg-color-white",
        styles.outline,
        outerClassName,
        {
          [styles["outline-hover"]]: !disableOutlineHover
        }
      )}
    >
      <div
        className={cx(
          styles["tiptap-textfield-scroll"],
          "w-100-percent",
          workspaceWrapperClassName
        )}
        id={id}
        style={maxHeight ? { maxHeight } : undefined}
      >
        <Workspace
          className={className}
          container={container}
          extensionsConfig={extensionsConfig}
          disableAutofocus={!autoFocus}
          disableFormattingMenu={disableFormattingMenu}
          disableWhite={disableWhite}
          disableBubble={disableBubble}
          enableHover={enableHover}
          overrideKeydown={overrideKeydown}
          initialContent={initialValue}
          assetNodeType={assetNodeType}
          onUpdate={onChange}
          chipsToUse={chipsWithSnippets}
          placeholder={label}
          richTextFieldClassName={richTextFieldClassName}
          saveWorkspaceType={saveWorkspaceType}
          variant={variant}
          ref={ref}
          id={id}
        />
      </div>
      <div
        className={cx(
          {
            [styles["action-button-width"]]: additionalActions,
            [styles.slim]: !additionalActions
          },
          "p-t-6px flex align-i-center"
        )}
      >
        <AddChipButton
          handleAddChip={handleAddChip}
          chipsToUse={chipsWithSnippets}
          className={cx({
            "add-chip-button": !additionalActions,
            "asset-button": additionalActions
          })}
        />
        {additionalActions}
      </div>
    </div>
  );
};

TipTapTextField.displayName = "TipTapTextField";

export const TipTapTextFieldContainer = (
  props: ITipTapTextField & { defaultExpanded?: boolean }
) => {
  const handleTextFieldKeyDown = useHandleTextFieldKeyDown();
  return (
    <TipTapTextField
      {...props}
      overrideKeydown={
        props.extensionsConfig?.disableDefaultEnter
          ? handleTextFieldKeyDown
          : undefined
      }
      variant="standard"
      saveWorkspaceType="json"
      disableFormattingMenu={props.disableFormattingMenu ?? true}
      extensionsConfig={{
        disableDefaultEnter: true,
        ...props.extensionsConfig
      }}
      richTextFieldClassName="p-0px-imp"
      outerClassName={cx(styles["input-padding"], props.outerClassName)}
    />
  );
};

export default TipTapTextField;
