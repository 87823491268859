import { Typography } from "@mui/material";
import { FIVE_MEGA_BYTES_IN_BYTES, type FileReference } from "@toolflow/shared";
import { createContext, ReactNode, useContext, useState } from "react";
import { type Accept, ErrorCode, useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { UploadIcon } from "../../../globalTheme/icons/icons";
import { setErrorMessage } from "../../../stores/actions";
import CircleIcon from "../../../utilities/components/icons/CircleButton";
import { parsedClassNames } from "../../../utilities/functions/parsedClassNames";
import styles from "./fileDropzone.module.css";
import useDragListener from "./hooks/useDragListener";

const cx = parsedClassNames.bind(styles);

export function isRawFile(file: File | FileReference): file is File {
  return (
    !!(file as File).name && !!(file as File).size && !!(file as File).type
  );
}

interface FullScreenFileDropzone {
  files: (File | FileReference)[];
  acceptedExtensions?: Accept;
  uploadInProgress: boolean;
  addFiles(files: File[]): void;
  removeFile(index: number): void;
  updateFile(index: number, newFile: FileReference | null): void;
}

const FullScreenFileDropzoneContext =
  createContext<FullScreenFileDropzone | null>(null);

export default function FullScreenFileDropzoneProvider({
  className,
  dropLabel = "",
  helperText = "",
  acceptedExtensions,
  children
}: {
  className?: string;
  dropLabel?: string;
  helperText?: string;
  acceptedExtensions?: Accept;
  children?: ReactNode;
}) {
  const isDragging = useDragListener();
  const dispatch = useDispatch();
  const [files, setFiles] = useState<(File | FileReference)[]>([]);

  const uploadInProgress = files.some((file) => isRawFile(file));

  function addFiles(droppedFiles: File[]) {
    setFiles((prev) => {
      return [...prev, ...droppedFiles];
    });
  }

  function removeFile(index: number) {
    setFiles((prev) => prev.filter((_, idx) => idx !== index));
  }

  function updateFile(index: number, newFile: FileReference | null) {
    if (!!newFile) {
      setFiles((prev) =>
        prev.map((oldFile, idx) => (idx === index ? newFile : oldFile))
      );
    } else {
      setFiles((prev) => prev.filter((_, idx) => idx !== index));
    }
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: addFiles,
    accept: acceptedExtensions,
    maxSize: FIVE_MEGA_BYTES_IN_BYTES,
    onDropRejected(fileRejections) {
      fileRejections.forEach((fileRejection) => {
        fileRejection.errors.forEach((error) => {
          if (error.code === ErrorCode.FileInvalidType) {
            dispatch(
              setErrorMessage(
                `Un-supported file type for "${fileRejection.file.name}".`
              )
            );
          } else if (error.code === ErrorCode.FileTooLarge) {
            dispatch(
              setErrorMessage(
                `File size cannot exceed 5MB for "${fileRejection.file.name}".`
              )
            );
          }
        });
      });
    }
  });

  return (
    <FullScreenFileDropzoneContext.Provider
      value={{
        files,
        uploadInProgress,
        acceptedExtensions,
        addFiles,
        removeFile,
        updateFile
      }}
    >
      {children}
      {isDragging && (
        <div
          {...getRootProps()}
          className={cx(styles["full-dropzone"], className)}
          onClick={open}
        >
          <input {...getInputProps()} />
          <CircleIcon size={20} Icon={UploadIcon} />
          <Typography
            align="center"
            color="white"
            variant="h8"
            className="m-t-8px"
          >
            {dropLabel}
          </Typography>
          <Typography
            variant="body2"
            align="center"
            sx={{ color: (theme) => theme.palette.text.secondary }}
          >
            {helperText}
          </Typography>
        </div>
      )}
    </FullScreenFileDropzoneContext.Provider>
  );
}

export function useFilesFromFullScreenDropzone() {
  const values = useContext(FullScreenFileDropzoneContext);
  if (!values) {
    throw new Error("Cannot get files from outside of dropzone context.");
  }
  return values;
}
