import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";
import type { SelectChangeEvent } from "@mui/material/Select";
import React, { FC, useEffect } from "react";
import useUpdateField from "../../useUpdateField";
import type {
  CustomToolInputField,
  CustomToolInputFieldTypes
} from "@toolflow/shared";
import { CREATE_USER_INPUT_LABEL } from "../../../../../tools/components/editorToolCard/inputs/helpers/constants";
import { FILE_UPLOAD } from "../../../../../tools/components/editorToolCard/inputs/helpers/inputConstants";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";

interface IDeepgramFileUploadSettingsProps {
  id: string;
  settings: { file: string };
  openInput: () => void;
  updateFieldKey: string;
  handleChangeInitialFieldType: (field: CustomToolInputFieldTypes) => void;
}

const DeepgramFileUploadSettings: FC<IDeepgramFileUploadSettingsProps> = (
  props
) => {
  const {
    id,
    settings,
    openInput,
    updateFieldKey,
    handleChangeInitialFieldType
  } = props;
  const getFiles = (inputs: CustomToolInputField[]) =>
    inputs.filter((field) => field.type === FILE_UPLOAD);
  const updateField = useUpdateField(id);
  const { state } = useToolbuilderContext();
  const { currentState } = state;
  const { toolInputFields } = currentState;
  const files = getFiles(toolInputFields);

  // we should come back to this
  // what happens when someone deletes a mapped file? should it go to
  // a new file, or go blank?
  // right now it doesn't auto map something, which may be ok?
  useEffect(() => {
    if (files.length === 0) {
      updateField("", updateFieldKey);
    }
    if (!files.map((f) => f.name).includes(settings.file) && files.length > 0) {
      updateField("", updateFieldKey);
    }
  }, [toolInputFields]);

  const { file } = settings;
  const fileValue = files.map((o) => o.name).includes(file) ? file : "";
  const showError = fileValue === "";
  const showNoFile = files.length === 0;

  const updateFileState = (event: SelectChangeEvent<string>) => {
    updateField(event.target.value, updateFieldKey);
  };

  const onFieldTypeChange = () => {
    handleChangeInitialFieldType(FILE_UPLOAD);
    openInput();
  };
  return (
    <FormControl margin="normal" fullWidth error={showError}>
      <InputLabel id="file-label">File to Transcribe</InputLabel>
      <Select
        labelId="file-label"
        id="file"
        // to make sure that when changing the type of the file, it doesn't throw a warning
        value={fileValue}
        fullWidth
        label="File to Transcribe"
        onChange={updateFileState}
        className="capitalize nowheel nodrag nopan bg-color-white"
      >
        <MenuItem value="" onClick={onFieldTypeChange}>
          {CREATE_USER_INPUT_LABEL}
        </MenuItem>
        {files.map((option: CustomToolInputField) => (
          <MenuItem value={option.name} key={option.id} className="capitalize">
            {option.name}
          </MenuItem>
        ))}
      </Select>
      {(showNoFile || showError) && (
        <FormHelperText>
          {showError ? "No mapped file to transcribe." : ""}
          {showNoFile
            ? `${showError ? " " : ""}Create a file input to use in this block.`
            : ""}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default DeepgramFileUploadSettings;
