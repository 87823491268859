import type { FileReference } from "@toolflow/shared";
import "swiper/css";
import { FreeMode, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { classNames } from "../../../../../../../utilities/functions/parsedClassNames";
import { useFilesFromFullScreenDropzone } from "../../../../../../files/fileUpload/FullScreenFileDropzone";
import FileChip from "./FileChip";

export default function ChatFilesList() {
  const { files } = useFilesFromFullScreenDropzone();

  return (
    <div
      className={classNames("oflow-hidden border-radius-24px", {
        "p-8px": !!files.length
      })}
    >
      <Swiper
        grabCursor
        scrollbar
        slidesPerView={"auto"}
        spaceBetween={8}
        freeMode={true}
        modules={[FreeMode, Pagination]}
      >
        {files.map((file, index) => {
          return (
            <SwiperSlide
              key={(file as File).name || (file as FileReference).fileName}
              className="w-fit-content-important"
            >
              <FileChip file={file} index={index} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
