import { EMessageRole } from "@toolflow/shared";
import { useThreadMessageContext } from "../../../contexts/ThreadMessageContext";
import UserMessage from "./UserMessage";
import {
  getFileNamesFromThreadMessage,
  removeContextFromThreadMessage
} from "./utils/threadMessageHelpers";
import AgentMessageWrapper from "./AgentMessageWrapper";

const ThreadMessage = () => {
  const { threadMessage } = useThreadMessageContext();
  const { role, content } = threadMessage;
  const isUserMessage = role === EMessageRole.USER;

  if (!content) return null;

  if (isUserMessage) {
    const fileNames = getFileNamesFromThreadMessage(threadMessage);
    return (
      <UserMessage
        messageContent={removeContextFromThreadMessage(threadMessage)}
        files={fileNames}
      />
    );
  }
  return <AgentMessageWrapper message={threadMessage} />;
};

export default ThreadMessage;
