import React, { useEffect } from "react";

import { PostHogProvider } from "posthog-js/react";
import { posthog } from "posthog-js";
import useGetCurrentUser from "../../features/user/hooks/useGetCurrentUser";
import { useAuthorizedTier } from "../../features/billing/hooks/useAuthorizedTier";

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST
};

const useIdentifyPosthogUser = () => {
  const user = useGetCurrentUser();
  const tier = useAuthorizedTier();

  useEffect(() => {
    if (user?._id) {
      const { billing, ...rest } = user;
      posthog.identify(user._id.toString(), { ...rest, tier });
    }
  }, [user?._id]);
};

const InitializedPostHogProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  useIdentifyPosthogUser();
  return (
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      {children}
    </PostHogProvider>
  );
};

export default InitializedPostHogProvider;
