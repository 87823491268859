import { useDispatch } from "react-redux";
import RemixButton from "../../../../../../../utilities/components/icons/RemixButton";
import { setAssetListBig } from "../../../../state/workspaceSlice";
import React from "react";
import { CollapseIcon } from "../../../../../../../globalTheme/icons/icons";

function CondenseAssetListButton() {
  const dispatch = useDispatch();
  const makeSmall = () => {
    dispatch(setAssetListBig(false));
  };
  return (
    <RemixButton icon={CollapseIcon} text="Condense" onClick={makeSmall} />
  );
}

export default CondenseAssetListButton;
