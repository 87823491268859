import { AssetValueType, getValueTypeByAssetValue } from "@toolflow/shared";
import {
  AssetContextComponent,
  useAssetContext
} from "../../../../../../utilities/contexts/AssetContext";
import useWorkspaceAssetById from "../../../assets/useWorkspaceAssetById";
import useGetVisibleAssetValue from "../../../hooks/useGetVisibleAssetValue";
import DallEAsset from "./dallEAsset/DallEAsset";
import SheetWorkspaceContainer from "./sheetAsset/sheet/SheetWorkspaceContainer";
import StructuredAsset from "./structuredAsset/StructuredAsset";
import TextAsset from "./textAsset/TextAsset";

const componentsMap = {
  [AssetValueType.String]: TextAsset,
  [AssetValueType.Sheet]: SheetWorkspaceContainer,
  [AssetValueType.DallE]: DallEAsset,
  [AssetValueType.Structured]: StructuredAsset
};

function AssetSwitchInner() {
  const value = useGetVisibleAssetValue();
  const { asset } = useAssetContext();
  let Component: () => JSX.Element;

  if (asset.valueType === AssetValueType.Sheet) {
    Component = componentsMap[asset.valueType];
  } else {
    const { valueType } = getValueTypeByAssetValue(value);
    Component = componentsMap[valueType];
  }

  return (
    <div className="pos-relative w-100-percent h-100-percent flex">
      <Component />
    </div>
  );
}

function AssetSwitch({ assetId }: { assetId: string }) {
  const asset = useWorkspaceAssetById({ id: assetId });

  if (!asset) {
    return null;
  }

  return (
    <AssetContextComponent asset={asset}>
      <AssetSwitchInner />
    </AssetContextComponent>
  );
}

export default AssetSwitch;
