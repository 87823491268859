import type {
  CurrentUserResponseFE,
  UpdateUserBody,
  UpdateUserResponseFE,
  UserDetailsResponse
} from "@toolflow/shared";
import { authenticatedApi } from "../authenticatedAPI";
import { setSuccessMessage } from "../../stores/actions";
import { CURRENT_USER_TAG_TYPE } from "../cacheTagConstants";

export const userApi = authenticatedApi.injectEndpoints({
  endpoints: (builder) => ({
    getProfileDetails: builder.query<UserDetailsResponse, string>({
      query: (profileId) => ({
        url: `userDetails/p/${profileId}`,
        method: "GET"
      })
    }),
    getCurrentUser: builder.query<CurrentUserResponseFE, void>({
      query: () => ({
        url: `user`,
        method: "GET"
      }),
      providesTags: [CURRENT_USER_TAG_TYPE]
    }),
    getUserFromAuthId: builder.query<CurrentUserResponseFE, string>({
      query: (authId) => ({
        url: `user/authId/${authId}`,
        method: "GET"
      })
    }),
    updateCurrentUser: builder.mutation<
      UpdateUserResponseFE,
      { updateFields: UpdateUserBody; successMessage: string }
    >({
      query: ({ updateFields }) => ({
        url: "user",
        method: "PATCH",
        body: updateFields
      }),
      async onQueryStarted({ successMessage }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setSuccessMessage(successMessage));
        } catch {}
      },
      invalidatesTags: [CURRENT_USER_TAG_TYPE]
    })
  })
});

export const userApiEndpoints = userApi.endpoints;

export const {
  useUpdateCurrentUserMutation,
  useGetCurrentUserQuery,
  useGetProfileDetailsQuery,
  useGetUserFromAuthIdQuery
} = userApi;
