import React from "react";

import type { IUpdateTextFieldBaseProps } from "@toolflow/shared";
import { useFormContext } from "react-hook-form";
import TextField from "@mui/material/TextField";
import ErrorContainer from "../errors/ErrorContainer";
import handleEnterBlur from "../../functions/handleEnterBlur";

function UpdateTextFieldBase({
  label,
  fieldLabel,
  variant = "outlined",
  size = "small",
  margin = "normal",
  fullWidth = true,
  placeholder = "",
  registerOptions,
  showErrors,
  className = "",
  multiline,
  maxRows,
  rows,
  inputProps
}: Readonly<IUpdateTextFieldBaseProps>) {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  return (
    <TextField
      key={fieldLabel}
      multiline={multiline}
      maxRows={maxRows}
      label={label}
      variant={variant}
      placeholder={placeholder}
      {...register(fieldLabel, registerOptions)}
      onKeyDown={handleEnterBlur}
      autoComplete="off"
      className={className}
      size={size}
      margin={margin}
      rows={rows}
      error={showErrors && !!errors[fieldLabel]}
      helperText={showErrors && <ErrorContainer fieldName={fieldLabel} />}
      fullWidth={fullWidth}
      inputProps={inputProps}
    />
  );
}

export default UpdateTextFieldBase;
