import { ButtonBase, Chip, CircularProgress } from "@mui/material";
import type { FileReference } from "@toolflow/shared";
import { useEffect } from "react";
import "swiper/css";
import {
  CloseIcon,
  FileTextFillIcon
} from "../../../../../../../globalTheme/icons/icons";
import {
  isRawFile,
  useFilesFromFullScreenDropzone
} from "../../../../../../files/fileUpload/FullScreenFileDropzone";
import useDeleteFile from "../../../../../../files/fileUpload/hooks/useDeleteFile";
import useFileUpload from "../../../../../../files/fileUpload/hooks/useFileUpload";

export default function FileChip({
  file,
  index
}: {
  file: File | FileReference;
  index: number;
}) {
  const fileName = (file as File).name || (file as FileReference).fileName;
  const { acceptedExtensions, removeFile, updateFile } =
    useFilesFromFullScreenDropzone();
  const { tmpFile, progress, abortUpload, handleFileSelect } = useFileUpload({
    setCurrentFile: (fileRef) => updateFile(index, fileRef),
    accept: acceptedExtensions
  });
  const { handleDeleteFile } = useDeleteFile((fileRef) =>
    updateFile(index, fileRef)
  );

  useEffect(() => {
    // Making sure file is only uploaded once on mount
    // On dev server with strict mode, on each mount react unmounts and remounts the components, essentially running useEffect twice
    const timeout = setTimeout(async () => {
      if (isRawFile(file) && !tmpFile) {
        await handleFileSelect([file]);
      }
    }, 1);
    return () => clearTimeout(timeout);
  }, [file]);

  async function handleRemove() {
    removeFile(index);
    if (isRawFile(file)) {
      abortUpload();
    } else {
      await handleDeleteFile(file._id);
    }
  }

  return (
    <Chip
      icon={<FileTextFillIcon size={24} />}
      label={fileName}
      onDelete={handleRemove}
      deleteIcon={
        <ButtonBase className="border-radius-24px bg-light-grey p-2px">
          <CloseIcon size={12} />
          <CircularProgress
            variant="determinate"
            value={progress}
            size={16}
            className="pos-absolute"
          />
        </ButtonBase>
      }
      className="p-h-12px p-v-24px"
    />
  );
}
