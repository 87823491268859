import { FormControl } from "@mui/material";
import {
  CustomToolInputFieldTypesEnum,
  DynamicFieldType,
  type DynamicListFieldProps
} from "@toolflow/shared";
import InputListComponent from "../../../../../../../../tools/components/inputs/InputListComponent";
import useUpdateField from "../../../../../useUpdateField";
import useFieldsByTypeHelper from "../../../useFieldsByTypeHelper";
import DynamicFieldWrapper from "../../dynamicFieldWrapper/DynamicFieldWrapper";
import { CREATE_USER_INPUT_LABEL } from "../../../../../../../../tools/components/editorToolCard/inputs/helpers/constants";

export function DynamicListField({
  field,
  settings,
  id
}: DynamicListFieldProps) {
  const { label, subtitle, disabled } = field;
  const {
    fieldKey,
    typeKey,
    defaultValue = [],
    filterAvailableFields
  } = field.config;
  const { getFieldValue } = useFieldsByTypeHelper({ settings });
  const updateField = useUpdateField(id);

  const dynamicFieldValue = getFieldValue(typeKey, CREATE_USER_INPUT_LABEL);
  const fieldValue = getFieldValue(fieldKey, defaultValue);

  return (
    <FormControl margin="normal" fullWidth disabled={disabled}>
      <DynamicFieldWrapper
        id={id}
        type={dynamicFieldValue}
        label={label}
        subtitle={subtitle}
        configs={field.config}
        fieldValue={fieldValue}
        filterOptions={filterAvailableFields}
        addInputType={CustomToolInputFieldTypesEnum.TAGS_INPUT}
        onTypeChangeCallback={(value) => {
          if (value === DynamicFieldType.Dynamic) {
            updateField(CREATE_USER_INPUT_LABEL, fieldKey);
          } else {
            updateField(defaultValue, fieldKey);
          }
        }}
      >
        <div className="nowheel nodrag nopan m-t-16px">
          <InputListComponent
            disabled={disabled}
            valuesList={fieldValue}
            handleChange={(value) => updateField(value, fieldKey)}
          />
        </div>
      </DynamicFieldWrapper>
    </FormControl>
  );
}
