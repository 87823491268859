import { ButtonBase, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { GUIDELINE_URL } from "./Guidelines";
import { PRIVACY_URL } from "./Privacy";
import { TERMS_OF_SERVICES_URL } from "./TermsOfService";

function Docs({ typographyClassName = "" }: { typographyClassName?: string }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (url: string) => () => {
    setAnchorEl(null);
    window.open(url, "_blank");
  };

  return (
    <>
      <ButtonBase
        aria-controls={open ? "docs-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className={typographyClassName}
      >
        Docs
      </ButtonBase>

      <Menu
        id="docs-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "docs-menu"
        }}
      >
        <MenuItem onClick={handleClose(TERMS_OF_SERVICES_URL)}>Terms</MenuItem>
        <MenuItem onClick={handleClose(PRIVACY_URL)}>Privacy</MenuItem>
        <MenuItem onClick={handleClose(GUIDELINE_URL)}>Guideline</MenuItem>
      </Menu>
    </>
  );
}

export default Docs;
