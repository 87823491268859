import {
  AssetValue,
  AssetValueType,
  UserInputResponseWithHandledError
} from "../../types";
import { isHandledChatStackError } from "./isHandledChatStackError";
import { isOpenAIImageArray } from "./isOpenAIImageArray";
import { validateAndParseJson } from "./validateAndParseJson";

export function getValueTypeByAssetValue(
  assetValue: AssetValue | UserInputResponseWithHandledError
) {
  let value: AssetValue = "";
  let valueType: AssetValueType = AssetValueType.Structured;

  if (typeof assetValue === "string") {
    const jsonValue = validateAndParseJson(assetValue);
    if (jsonValue) {
      value = jsonValue;
    } else {
      value = assetValue;
      valueType = AssetValueType.String;
    }
  } else if (isHandledChatStackError(assetValue)) {
    value = assetValue.data.message;
    valueType = AssetValueType.String;
  } else if (isOpenAIImageArray(assetValue)) {
    value = assetValue;
    valueType = AssetValueType.DallE;
  } else if (typeof assetValue === "boolean") {
    value = assetValue.toString();
    valueType = AssetValueType.String;
  } else if (typeof assetValue === "object") {
    value = assetValue;
    valueType = AssetValueType.Structured;
  }

  return { value, valueType };
}
