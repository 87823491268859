import React from "react";
import TogglePrimaryHeaderTextFieldBase from "../../../../utilities/components/textFields/TogglePrimaryHeaderTextFieldBase";
import { useFormContext } from "react-hook-form";
import UpdateWorkflowNameTextField from "./UpdateWorkflowNameTextfield";
import {
  DEFAULT_WORKFLOW_NAME,
  WORKFLOW_NAME_FIELD_LABEL
} from "./WorkflowBuilderConstants";
import type { TWorkflowBuilderForm } from "@toolflow/shared";
import ErrorContainer from "../../../../utilities/components/errors/ErrorContainer";

const ToggleWorkflowNameTextField = () => {
  const { watch } = useFormContext<TWorkflowBuilderForm>();
  const workflowName = watch(WORKFLOW_NAME_FIELD_LABEL);

  return (
    <TogglePrimaryHeaderTextFieldBase
      value={workflowName || DEFAULT_WORKFLOW_NAME}
      errorMessage={
        <ErrorContainer fieldName={WORKFLOW_NAME_FIELD_LABEL} withForm />
      }
    >
      <UpdateWorkflowNameTextField />
    </TogglePrimaryHeaderTextFieldBase>
  );
};

export default ToggleWorkflowNameTextField;
