import React from "react";
import { Alert, Button } from "@mui/material";
import { useStopImpersonationMutation } from "../../../../../../ToolflowAPI/rtkRoutes/impersonationApi";
import useGetCurrentUser from "../../../../../user/hooks/useGetCurrentUser";
import styles from "./impersonationAlert.module.css";

import { parsedClassNames } from "../../../../../../utilities/functions/parsedClassNames";
import { useGetUserFromAuthIdQuery } from "../../../../../../ToolflowAPI/rtkRoutes/userApi";
import { EMPLOYEE_FE } from "../../../../builder/toolBuilder/common/utils";
import { RootState } from "../../../../../../stores/store";
import { useSelector } from "react-redux";

const cx = parsedClassNames.bind(styles);

export const ImpersonationAlert: React.FC = () => {
  const impersonatedUser = useGetCurrentUser();
  const [stopImpersonation, { isLoading }] = useStopImpersonationMutation();
  const authId = useSelector(
    (state: RootState) => state.auth.auth0Content.user?.sub
  );
  const { data } = useGetUserFromAuthIdQuery(authId ?? "", {
    skip: !impersonatedUser?.impersonatingId || !authId
  });

  if (
    !impersonatedUser?.impersonatingId ||
    !data ||
    data?.user.toolflowEmployeeType !== EMPLOYEE_FE
  ) {
    return null;
  }

  const handleStopImpersonation = async () => {
    await stopImpersonation();
  };

  return (
    <Alert
      severity="warning"
      action={
        <Button
          color="inherit"
          size="small"
          onClick={handleStopImpersonation}
          disabled={isLoading}
        >
          {isLoading ? "Stopping..." : "Stop Impersonation"}
        </Button>
      }
      className={cx("impersonation-alert")}
    />
  );
};
