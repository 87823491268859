import { BlockSettingsBase } from "../blocks";
import { CustomToolInputFieldTypes } from "../inputs";
import { $TSAllowedAny, UpdateSType, ValidatedInput } from "../types";
import * as t from "./fieldsByType";

export const DYNAMIC_FIELD_PREFIX = "dynamic-";

export enum FieldType {
  SelectInputV1 = "select-input-v1",
  CustomSelectInput = "custom-select-input",
  JsonSchemaInput = "json-schema-input",
  PromptInput = "prompt-input",
  PromptInputV2 = "prompt-input-v2",
  Slider = "slider",
  NewSelectInput = "new-select-input",
  SelectTools = "select-tools",
  WordReplaceField = "word-replace",
  FileField = "file-field",
  SelectKnowledgeField = "select-knowledge-field",

  // Dynamic Fields
  // These start with "dynamic-" prefix.
  DynamicListField = DYNAMIC_FIELD_PREFIX + "list-field",
  DynamicInputField = DYNAMIC_FIELD_PREFIX + "input-field",
  DynamicSwitchField = DYNAMIC_FIELD_PREFIX + "switch-field",
  DynamicSelectField = DYNAMIC_FIELD_PREFIX + "select-field",
  DynamicSliderField = DYNAMIC_FIELD_PREFIX + "slider-field",

  Group = "group"
}

export type UpdateField = (
  updateValue: UpdateSType,
  dataProperty: string,
  nestedArrayIndex?: number,
  nestedArrayProp?: string
) => void;

export type SelectOption =
  | string
  | { label: string; value: string; subText?: string };

export interface FieldsByTypePropsBase {
  settings: BlockSettingsBase;
  id: string;
  updateField: UpdateField;
}

export interface FieldsByTypeProps {
  configs: FieldConfig;
}

export interface BaseInputFieldProps {
  label: string;
  subtitle?: string;
  disabled?: boolean;
}

export type FieldTypeConfigs =
  | t.SelectToolsFieldType
  | t.CustomSelectInputFieldType
  | t.JsonSchemaInputFieldType
  | t.SelectFieldV1FieldType
  | t.PromptInputV2FieldType
  | t.PromptInputFieldType
  | t.FileFieldType
  | t.SliderFieldType
  | t.NewSelectInputFieldType
  | t.WordReplaceFieldType
  | t.SelectKnowledgeFieldType
  | t.DynamicListFieldType
  | t.DynamicSwitchFieldType
  | t.DynamicSelectFieldType
  | t.DynamicInputFieldType
  | t.DynamicSliderFieldType;

export type FieldTypeComponentProps =
  | t.CustomSelectInputWrapperProps
  | t.JsonSchemaInputWrapperProps
  | t.SelectFieldV1Props
  | t.TextInputContainerProps
  | t.PromptInputWrapperProps
  | t.FileFieldProps
  | t.SliderFieldProps
  | t.NewSelectInputWrapperProps
  | t.WordReplaceFieldProps
  | t.SelectKnowledgeFieldProps
  | t.DynamicListFieldProps
  | t.DynamicSwitchFieldProps
  | t.DynamicInputFieldProps
  | t.DynamicSelectFieldProps
  | t.DynamicSliderFieldProps;

export type TFieldsByType = (FieldTypeConfigs | FieldsGroup)[];

export interface FieldConfig<T = BlockSettingsBase> {
  id: string;
  data: T;
  updateField: UpdateField;
  fields: TFieldsByType;
}

export interface FieldsGroup {
  type: FieldType.Group;
  label: string;
  subtitle?: string;
  fields: FieldTypeConfigs[];
}

export interface DynamicFieldConfigBase {
  fieldKey: string;
  typeKey: string;
  defaultValue?: $TSAllowedAny;
  forceDynamic?: boolean;
  filterAvailableFields?(options: ValidatedInput[]): ValidatedInput[];
  allowedFields?: CustomToolInputFieldTypes[];
}

export enum DynamicFieldType {
  Dynamic = "dynamic",
  Preset = "preset"
}

export interface DynamicFieldValue<T = $TSAllowedAny> {
  type: DynamicFieldType;
  value: T;
}

export type ExtractDynamicField<T> =
  T extends DynamicFieldValue<infer U> ? U : T;

export type ConvertDynamicFields<T> = {
  [K in keyof T]: T[K] extends DynamicFieldValue
    ? ExtractDynamicField<T[K]>
    : T[K] extends object
      ? ConvertDynamicFields<T[K]>
      : T[K];
};
