import { Typography } from "@mui/material";
import React from "react";
import {
  EntityContextComponent,
  useEntityContext
} from "../../context/EntityContext";
import type { TMarketplaceEntity } from "@toolflow/shared";
import { EntityActions } from "./EntityActions";
import { classNames } from "../../../../utilities/functions/parsedClassNames";
import { useToolPadding } from "./useToolPadding";

function EntityInfo() {
  const { entity } = useEntityContext();
  const padding = useToolPadding();

  return (
    <div className={classNames("p-t-32px p-b-16px", padding)}>
      <div className="flex align-i-center justify-space-between">
        <Typography variant="h7" noWrap>
          {entity.name}
        </Typography>
        <EntityActions />
      </div>
      {entity.description && (
        <Typography
          variant="body2"
          className="m-t-8px"
          sx={{ color: (theme) => theme.palette.text.secondary }}
        >
          {entity.description}
        </Typography>
      )}
    </div>
  );
}

export function EntityInfoContainer({
  entity
}: {
  entity: TMarketplaceEntity;
}) {
  return (
    <EntityContextComponent entity={entity}>
      <EntityInfo />
    </EntityContextComponent>
  );
}

export default EntityInfo;
