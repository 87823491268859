import { useState } from "react";
import useAllowAPIKeys from "./useAllowAPIKeys";
export const useCreateAPIKeyButton = () => {
  const [open, setOpen] = useState(false);
  const isAuthorized = useAllowAPIKeys();
  return {
    open,
    setOpen,
    disabled: !isAuthorized
  };
};
