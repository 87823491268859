import { useDeleteFileMutation } from "../../../../ToolflowAPI/rtkRoutes/fileApi";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "../../../../stores/actions";
import type { FileReference } from "@toolflow/shared";

const useDeleteFile = (
  setCurrentFile: (file: FileReference | null) => void
) => {
  const reduxDispatch = useDispatch();
  const [deleteFile, { isLoading }] = useDeleteFileMutation();

  const handleDeleteFile = async (id?: string) => {
    if (id) {
      try {
        await deleteFile(id)
          .unwrap()
          .then(() => {
            setCurrentFile(null);
          });
      } catch {
        reduxDispatch(setErrorMessage("Error deleting file"));
      }
    }
  };

  return { handleDeleteFile, isLoading };
};

export default useDeleteFile;
