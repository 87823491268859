import React from "react";
import TogglePrimaryHeaderTextFieldBase from "../../../../utilities/components/textFields/TogglePrimaryHeaderTextFieldBase";
import { useFormContext } from "react-hook-form";
import ErrorContainer from "../../../../utilities/components/errors/ErrorContainer";
import UpdateAgentNameTextField from "./UpdateAgentNameTextfield";
import { AGENT_NAME_FIELD_LABEL } from "./constants/fields";
import { DEFAULT_AGENT_NAME } from "./constants/defaults";
import type { TAgentBuilderForm } from "@toolflow/shared";

const ToggleAgentNameTextField = () => {
  const { watch } = useFormContext<TAgentBuilderForm>();
  const agentName = watch(AGENT_NAME_FIELD_LABEL);

  return (
    <TogglePrimaryHeaderTextFieldBase
      value={agentName || DEFAULT_AGENT_NAME}
      errorMessage={
        <ErrorContainer fieldName={AGENT_NAME_FIELD_LABEL} withForm />
      }
    >
      <UpdateAgentNameTextField />
    </TogglePrimaryHeaderTextFieldBase>
  );
};

export default ToggleAgentNameTextField;
