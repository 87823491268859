import {
  BlockType,
  DEFAULT_VERSION_VALUE,
  OutputConfigOptions,
  type AllBlockTypes,
  type CustomToolOutputField,
  type DecimalString,
  type JSONSchemaItem,
  type OriginalToolState,
  type OutputConfigGetterFunction,
  type StructuredBlockData
} from "@toolflow/shared";
import { isArray } from "lodash";
import { checkIsIterationChild } from "../../../../hooks/useIterationHelpers";

function isListSchema(keys: string[], schema: JSONSchemaItem[]) {
  if (!keys.length) return false;

  const key = keys.at(0);

  if (isArray(schema)) {
    const next = schema.find((s) => s.fieldLabel === key);

    if (next) {
      if (keys.length > 1 && next.type === "object") {
        return isListSchema(keys.slice(1), next.items);
      } else {
        return keys.length === 1 && next.type === "array";
      }
    }
  }
  return false;
}

function setIfIterationChild(
  id: string,
  configs: Set<string>,
  currentState: OriginalToolState
) {
  const parentBlockNames = currentState.availableFields[id] || [];
  const isIterationChild = checkIsIterationChild(
    parentBlockNames,
    currentState.blocks
  );

  if (isIterationChild) {
    configs.add(OutputConfigOptions.List);
  }
}

function setIfHasArrayOutputFromSchema(
  schema: JSONSchemaItem[],
  configs: Set<string>,
  toolOutputField: CustomToolOutputField
) {
  const keys = toolOutputField.config?.nestedOutputKey?.split(".") || [];
  const isListOutput = isListSchema(keys, schema);

  if (isListOutput) {
    configs.add(OutputConfigOptions.List);
  }
}

export const outputConfigOptions: Record<
  string,
  Record<DecimalString, OutputConfigGetterFunction>
> = {
  [BlockType.Structured]: {
    [DEFAULT_VERSION_VALUE]: ({ id, data, toolOutputField, currentState }) => {
      const { settings } = data as StructuredBlockData;

      const configs: Set<OutputConfigOptions> = new Set();

      setIfIterationChild(id, configs, currentState);
      setIfHasArrayOutputFromSchema(settings.schema, configs, toolOutputField);

      return [...configs];
    }
  },
  default: {
    [DEFAULT_VERSION_VALUE]: ({ id, currentState }) => {
      const configs: Set<OutputConfigOptions> = new Set();

      setIfIterationChild(id, configs, currentState);

      return [...configs];
    }
  }
};

export function getOutputConfigOptionsFn(
  type: AllBlockTypes | "default" = "default",
  version: DecimalString = DEFAULT_VERSION_VALUE
) {
  return (
    outputConfigOptions[type]?.[version] ||
    outputConfigOptions[type]?.[DEFAULT_VERSION_VALUE] ||
    outputConfigOptions.default[DEFAULT_VERSION_VALUE]
  );
}
