import {
  type CustomToolOutputField,
  type SanitizedUserType,
  type ToolAsset,
  AssetSourceType,
  type Asset,
  getValueTypeByAssetValue
} from "@toolflow/shared";
import { v4 as uuidv4 } from "uuid";
import { getValueTypeFromOutputField } from "../../../../tools/components/editorToolCard/inputs/helpers/outputFieldHelpers";

export function getUpdatedAssets(
  toolOutputFields: CustomToolOutputField[],
  savedAssets: Asset[],
  componentId: string,
  addAssetsCallback:
    | ((id: string, outputField: CustomToolOutputField) => void)
    | undefined,
  toolId: string,
  creator: SanitizedUserType
): ToolAsset[] {
  return toolOutputFields.map((outputField) => {
    // Check for a matching saved asset
    // this will only be dalle or string
    const existingAsset: ToolAsset | undefined = savedAssets.find(
      (i) =>
        i.data?.outputId === outputField.id &&
        i.sourceIdentifier === componentId &&
        i.valueType
    ) as ToolAsset | undefined;

    const valueType = existingAsset?.value
      ? getValueTypeByAssetValue(existingAsset.value).valueType
      : getValueTypeFromOutputField(outputField.type);

    if (existingAsset) {
      // Update existing asset
      return {
        ...existingAsset,
        name: outputField.name, // Updated name
        value: existingAsset.value, // Keep existing value or modify as needed
        valueType
      } as ToolAsset;
    } else {
      const id = uuidv4();
      if (addAssetsCallback) {
        addAssetsCallback(id, outputField);
      }
      // Create new asset
      return {
        id,
        name: outputField.name,
        label: outputField.config?.label || outputField.name,
        value: "",
        valueType,
        source: AssetSourceType.ToolOutputPlaceholder,
        sourceIdentifier: componentId,
        data: {
          toolId,
          creatorId: creator._id,
          outputId: outputField.id
        },
        loading: false
      } as ToolAsset;
    }
  });
}
