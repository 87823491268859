export type TIntegrationType =
  | "slack"
  | "salesforce_rest_api"
  | "airtable"
  | "calendly"
  | "hubspot"
  | "google_drive"
  | "google_docs"
  | "gmail"
  | "google_appsheet"
  | "google_calendar"
  | "linkedin"
  | "notion";

export enum UserIntegrationType {
  SLACK = "slack",
  SALESFORCE = "salesforce_rest_api",
  AIRTABLE = "airtable",
  CALENDLY = "calendly",
  HUBSPOT = "hubspot",
  GOOGLE_DRIVE = "google_drive",
  GOOGLE_DOCS = "google_docs",
  GMAIL = "gmail",
  GOOGLE_SHEETS = "google_appsheet",
  GOOGLE_CALENDAR = "google_calendar",
  LINKEDIN = "linkedin",
  NOTION = "notion"
}
