import React from "react";
import UpdateTextFieldBase from "../../../../utilities/components/textFields/UpdateTextFieldBase";
import type { IUpdateTextFieldContainerProps } from "@toolflow/shared";
import { AGENT_DESCRIPTION_FIELD_LABEL } from "./constants/fields";
import { ABOUT_CHARACTER_LENGTH } from "../../../tools/components/editorToolCard/inputs/helpers/constants";

const UpdateAgentDescriptionTextField = ({
  margin = "none",
  size = "small",
  label,
  placeholder
}: IUpdateTextFieldContainerProps) => {
  return (
    <UpdateTextFieldBase
      size={size}
      placeholder={placeholder}
      label={label}
      margin={margin}
      fieldLabel={AGENT_DESCRIPTION_FIELD_LABEL}
      className={"bg-color-white"}
      inputProps={{
        maxLength: ABOUT_CHARACTER_LENGTH
      }}
    />
  );
};

export default UpdateAgentDescriptionTextField;
