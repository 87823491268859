import React from "react";
import { Button, Container, Typography } from "@mui/material";
import {
  ArrowDownSmallIcon,
  HistoryIcon
} from "../../../../../globalTheme/icons/icons";
import { cx } from "./GetStarted";
import { SessionElementCard } from "../../../../workspace/components/SessionElementCard";
import type { StreamlinedWorkspace } from "@toolflow/shared";
import { toolflowPrimaryColor } from "../../../../../globalTheme/muiUtils/appTheme";
import { SessionHistoryListContainer } from "../../../../workspace/components/SessionHistoryListContainer";
import { DialogButtonContainer } from "../../../../../utilities/components/dialogs/DialogButtonContainer";

export const useSessionHistoryDialog = ({
  setOpen
}: {
  setOpen: (open: boolean) => void;
}) => {
  const onClickEntityWithClose = () => {
    setOpen(false);
  };

  const SessionElementWithOnClick = React.useCallback(
    ({
      workspace,
      className
    }: {
      workspace: StreamlinedWorkspace;
      className?: string;
    }) => (
      <SessionElementCard
        workspace={workspace}
        className={className}
        onClick={onClickEntityWithClose}
      />
    ),
    [onClickEntityWithClose]
  );

  return {
    setOpen,
    SessionElementWithOnClick,
    onClickEntityWithClose
  };
};

const SessionHistoryDialogContentInner = ({
  setOpen
}: {
  setOpen: (open: boolean) => void;
}) => {
  const { SessionElementWithOnClick } = useSessionHistoryDialog({ setOpen });
  return (
    <Container maxWidth="md" className="m-t-32px">
      <div className="flex align-i-center w-100-percent flex-wrap">
        <HistoryIcon
          size={20}
          color={toolflowPrimaryColor}
          className="m-r-8px"
        />
        <Typography variant="h7">Recent Sessions</Typography>
      </div>
      <SessionHistoryListContainer
        SessionElement={SessionElementWithOnClick}
        className="m-t-32px"
      />
    </Container>
  );
};

export function SessionHistoryButton({ className }: { className?: string }) {
  return (
    <DialogButtonContainer
      button={
        <Button
          variant="text"
          size="small"
          className={cx("bg-color-transparent", className)}
          endIcon={<ArrowDownSmallIcon size={18} />}
        >
          Session history
        </Button>
      }
      content={({ setOpen }) => (
        <SessionHistoryDialogContentInner setOpen={setOpen} />
      )}
    />
  );
}
