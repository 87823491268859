import React from "react";
import { useGetToolVersionResponseQuery } from "../../../ToolflowAPI/rtkRoutes/toolVersionResponseApi";
import { TVRContextComponent } from "./ToolVersionResponseContext";

function ToolVersionResponseContextContainer({
  tvrId,
  children
}: {
  tvrId?: string;
  children: React.ReactNode;
}) {
  const { data } = useGetToolVersionResponseQuery(tvrId || "", !tvrId);
  if (!data?.toolVersionResponse) {
    return null;
  }
  return (
    <TVRContextComponent tvr={data.toolVersionResponse}>
      {children}
    </TVRContextComponent>
  );
}

export default ToolVersionResponseContextContainer;
