import { ReactNode } from "react";
import FullScreenFileDropzoneProvider from "../../../../../../files/fileUpload/FullScreenFileDropzone";
import { ChatTextProvider } from "./hooks/useChatText";

export default function ChatProviders({ children }: { children: ReactNode }) {
  return (
    <FullScreenFileDropzoneProvider
      dropLabel="Drop a file to add it to conversation."
      // For mime-type reference visit: https://mimetype.io/all-types
      acceptedExtensions={{
        "text/plain": [".txt"],
        "application/msword": [".doc"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          [".docx"],
        "audio/mpeg": [".mp3"],
        "video/mp4": [".mp4"],
        "audio/mp4": [".m4a"],
        "video/webm": [".webm"],
        "video/mpeg": [".mpeg", ".mpg"],
        "audio/wav": [".wav"]
      }}
    >
      <ChatTextProvider>{children}</ChatTextProvider>
    </FullScreenFileDropzoneProvider>
  );
}
