import { IdBase, TSelect } from ".";
import { TCheckbox } from "./checkbox";
import {
  CompatibilityOptionWithOptions,
  CompatibilityOptionWithOutOptions
} from "./compatibilityOptions";
import { TCSVFileUpload } from "./csvFileUpload";
import { TDoubleTextfield } from "./doubleTextField";
import { TFileUpload } from "./fileUpload";
import { TMultiSelect } from "./multiSelect";
import { TTagsInput } from "./tags";
import { TTextFieldTypes } from "./textField";

// CustomToolInputFields are compatibility options with ids

export type CustomToolInputFieldTypes =
  | TSelect
  | TCheckbox
  | TTextFieldTypes
  | TCSVFileUpload
  | TFileUpload
  | TMultiSelect
  | TTagsInput
  | TDoubleTextfield;

// TODO: we should combine CustomeToolInputFieldTypes with Enum
export enum CustomToolInputFieldTypesEnum {
  TEXTFIELD = "textField",
  LARGE_TEXTFIELD = "largeTextField",
  SELECT = "select",
  CHECKBOX = "checkbox",
  FILE_UPLOAD = "fileUpload",
  MULTI_SELECT = "multiSelect",
  TAGS_INPUT = "tagsInput",
  DOUBLE_TEXTFIELD = "doubleTextfield",
  CSV_FILE_UPLOAD = "csvFileUpload",
  BLOCK_OUTPUT = "blockOutput"
}

// OpenAPI datatype specification
// https://swagger.io/docs/specification/v3_0/data-models/data-types/

export type OpenAPIDataType =
  | "string"
  | "integer"
  | "number"
  | "boolean"
  | "object"
  | "array";

export type TInputTypePrimitive = {
  type: OpenAPIDataType;
  items?: { type: OpenAPIDataType };
  properties?: {
    [key: string]: {
      type: OpenAPIDataType;
      description?: string;
    };
  };
  description?: string;
  required?: boolean | string[];
  additionalProperties?: boolean;
};

export type TInputTypePrimitiveMap = {
  [key in CustomToolInputFieldTypesEnum]: TInputTypePrimitive;
};

const textFieldType = {
  type: "object" as const,
  description:
    "This is the text field object containing data for the tool. Use the contextId field whenever possible. When using tool outputs as contextIds, pass the key of the output as the contextId. For instance if you want to use the blog text and the output is {'blog post': 'this is a blog post', tvrId: '6441cab9dc06a5e378195246' } then pass {'properties': {'contextId': 'blog post', 'textValue': ''}}. Do not pass the tvrId as the contextId.",
  properties: {
    contextId: {
      type: "string" as const,
      description:
        "Do not pass the tvrId as the contextId. If the text is in a flowArtifact or a file context use its id as the contextId field to reference it. If the text is in a tool output, use its key in the output object as its id. When using tool outputs as contextIds, pass the key of the output as the contextId. For instance if you want to use the blog text and the output is {'blog post': 'this is a blog post', tvrId: '6441cab9dc06a5e378195246' } then pass {'properties': {'contextId': 'blog post', 'textValue': ''}}. Do not pass the tvrId as the contextId."
    },
    textValue: {
      type: "string" as const,
      description:
        "Use this field when text isn't in the context id. Leave empty if the context id is present. Do not use this field if the contextId exists.  Otherwise this is the text of the text field."
    }
  },
  additionalProperties: false,
  required: ["textValue", "contextId"]
};

export const inputTypePrimitiveMap: TInputTypePrimitiveMap = {
  [CustomToolInputFieldTypesEnum.TEXTFIELD]: textFieldType,
  [CustomToolInputFieldTypesEnum.LARGE_TEXTFIELD]: textFieldType,
  [CustomToolInputFieldTypesEnum.SELECT]: { type: "string" },
  [CustomToolInputFieldTypesEnum.CHECKBOX]: { type: "boolean" },
  [CustomToolInputFieldTypesEnum.FILE_UPLOAD]: {
    type: "object",
    description: "This is the reference object containing data for the tool.",
    properties: {
      fileReferenceId: {
        type: "string",
        description: "Reference id of the file uploaded"
      },
      fileUrl: {
        type: "string",
        description: "Url of the uploaded file."
      }
    },
    additionalProperties: false,
    required: ["fileReferenceId", "fileUrl"]
  },
  [CustomToolInputFieldTypesEnum.MULTI_SELECT]: { type: "string" },
  [CustomToolInputFieldTypesEnum.TAGS_INPUT]: {
    type: "array",
    items: { type: "string" }
  },
  [CustomToolInputFieldTypesEnum.DOUBLE_TEXTFIELD]: { type: "string" }, // TODO: this is not right
  [CustomToolInputFieldTypesEnum.CSV_FILE_UPLOAD]: { type: "string" }, // TODO: this is not right
  [CustomToolInputFieldTypesEnum.BLOCK_OUTPUT]: { type: "string" }
};

export type TBlockOutput = "blockOutput";

export type ValidatedInputTypes = CustomToolInputFieldTypes | TBlockOutput;

export type CustomToolInputFieldWithOptions = CompatibilityOptionWithOptions &
  IdBase;

export type CustomToolInputFieldWithOutOptions =
  CompatibilityOptionWithOutOptions & IdBase;

export type CustomToolInputField =
  | CustomToolInputFieldWithOptions
  | CustomToolInputFieldWithOutOptions;
