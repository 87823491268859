import { FormControl, TextField } from "@mui/material";
import {
  CustomToolInputFieldTypesEnum,
  DynamicFieldType,
  type DynamicInputFieldConfig,
  type DynamicInputFieldType
} from "@toolflow/shared";
import { useFieldsByTypeContext } from "../../../FieldsByTypeContext";
import useFieldsByTypeHelper from "../../../useFieldsByTypeHelper";
import DynamicFieldWrapper from "../../dynamicFieldWrapper/DynamicFieldWrapper";
import { CREATE_USER_INPUT_LABEL } from "../../../../../../../../tools/components/editorToolCard/inputs/helpers/constants";

export default function DynamicInputField() {
  const { field, id, data, updateField } =
    useFieldsByTypeContext<DynamicInputFieldType>();
  const { config, label, subtitle, disabled } = field;
  const {
    fieldKey,
    typeKey,
    placeholder,
    htmlInputType,
    defaultValue,
    filterAvailableFields
  } = config as DynamicInputFieldConfig;
  const { getFieldValue } = useFieldsByTypeHelper({ settings: data });

  const dynamicFieldValue = getFieldValue(typeKey, CREATE_USER_INPUT_LABEL);
  const fieldValue = getFieldValue(fieldKey, defaultValue);

  return (
    <FormControl margin="normal" fullWidth disabled={disabled}>
      <DynamicFieldWrapper
        id={id}
        type={dynamicFieldValue}
        label={label}
        subtitle={subtitle}
        configs={field.config}
        fieldValue={fieldValue}
        addInputType={CustomToolInputFieldTypesEnum.TEXTFIELD}
        filterOptions={filterAvailableFields}
        onTypeChangeCallback={(type) => {
          if (type === DynamicFieldType.Dynamic) {
            updateField(CREATE_USER_INPUT_LABEL, fieldKey);
          } else {
            updateField(defaultValue, fieldKey);
          }
        }}
      >
        <div className="nowheel nodrag nopan m-t-16px">
          <TextField
            fullWidth
            size="small"
            value={fieldValue}
            placeholder={placeholder}
            type={htmlInputType}
            disabled={disabled}
            className="bg-color-white"
            onChange={(e) => {
              let value: string | number = e.target.value;
              if (htmlInputType === "number") {
                value = Number(value);
              }
              updateField(value, fieldKey);
            }}
          />
        </div>
      </DynamicFieldWrapper>
    </FormControl>
  );
}
