import { ControlledBubbleMenu, useRichTextEditorContext } from "mui-tiptap";
import { ReactNode, useMemo } from "react";
import { useMuiTipTapContext } from "../../context/MuiTipTapContext";

const CustomBubbleMenu = ({ children }: { children: ReactNode }) => {
  const { muiTipTapProps, isSlash, shouldRenderBubble } = useMuiTipTapContext();
  const { container } = muiTipTapProps;
  const editor = useRichTextEditorContext();
  const { anchor, head } = editor?.state.selection || { anchor: 0, head: 0 };
  const isStartToEnd = anchor < head;
  // we dont want the placement to change after it is open and someone clicks isSlash
  const placement = useMemo(() => {
    return isSlash || isStartToEnd ? "bottom-start" : "top";
  }, [shouldRenderBubble, editor]);

  if (!editor) return null;
  return (
    <ControlledBubbleMenu
      editor={editor}
      placement={placement}
      fallbackPlacements={["bottom"]}
      flipPadding={0}
      open={shouldRenderBubble}
      PaperProps={{
        className: "popover-paper-borders"
      }}
      className="z-index-max" // this is hacky -> we probably can do it with the container. Look at inputs drawer and the container that gets passed down, issues is that if we use a ref, it is under the paper, over the modal. and if on the paper gets cut off when it overflows
      container={container?.current}
    >
      {children}
    </ControlledBubbleMenu>
  );
};

export default CustomBubbleMenu;
