import { authenticatedApi } from "../authenticatedAPI";
import { setErrorMessage, setSuccessMessage } from "../../stores/actions";
import { CURRENT_USER_TAG_TYPE } from "../cacheTagConstants";
import type {
  TImpersonateUserRequest,
  TImpersonateUserResponse,
  TStopImpersonateUserResponse
} from "@toolflow/shared";

export const impersonationApi = authenticatedApi.injectEndpoints({
  endpoints: (builder) => ({
    startImpersonation: builder.mutation<
      TImpersonateUserResponse,
      TImpersonateUserRequest
    >({
      query: (body) => ({
        url: "internal/impersonate",
        method: "POST",
        body
      }),
      invalidatesTags: [CURRENT_USER_TAG_TYPE],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setSuccessMessage("Successfully started impersonation"));
          // Reload the page to reflect the impersonated user
          window.location.reload();
        } catch (error) {
          dispatch(
            setErrorMessage(
              error instanceof Error
                ? error.message
                : "Failed to start impersonation"
            )
          );
        }
      }
    }),
    stopImpersonation: builder.mutation<TStopImpersonateUserResponse, void>({
      query: () => ({
        url: "internal/stop-impersonation",
        method: "POST"
      }),
      invalidatesTags: [CURRENT_USER_TAG_TYPE],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setSuccessMessage("Successfully stopped impersonation"));
          // Reload the page to reflect the original user
          window.location.reload();
        } catch (error) {
          dispatch(
            setErrorMessage(
              error instanceof Error
                ? error.message
                : "Failed to stop impersonation"
            )
          );
        }
      }
    })
  })
});

export const { useStartImpersonationMutation, useStopImpersonationMutation } =
  impersonationApi;
