import React, { createContext, useContext } from "react";
import type { PopulatedToolVersionResponseFE } from "@toolflow/shared";
import ToolContextComponentContainer from "../../tools/contexts/ToolContextContainer";

interface ToolVersionResponseContextType {
  tvr: PopulatedToolVersionResponseFE;
}

const TVRContext = createContext<ToolVersionResponseContextType | undefined>(
  undefined
);

export function TVRContextComponent({
  children,
  tvr
}: Readonly<{
  children: React.ReactNode;
  tvr?: PopulatedToolVersionResponseFE;
}>) {
  if (!tvr) {
    return null;
  }
  return (
    <TVRContext.Provider value={{ tvr }}>
      <ToolContextComponentContainer toolId={tvr.toolId._id.toString()}>
        {children}
      </ToolContextComponentContainer>
    </TVRContext.Provider>
  );
}

export const useTVRContext = () => {
  const context = useContext(TVRContext);
  if (context === undefined) {
    throw new Error("useTVRContext must be used within a TVRContextProvider");
  }
  return context;
};

export default TVRContext;
