import useSetVisibleAsset from "./useSetVisibleAsset";

const useCloseAssetSidepanel = () => {
  const { setVisibleAsset } = useSetVisibleAsset();
  const closeAssetSidepanel = () => {
    setVisibleAsset("");
  };
  return { closeAssetSidepanel };
};

export default useCloseAssetSidepanel;
