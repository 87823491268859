import type {
  TMarketplaceUniversalEntity,
  TSearchToolsTabType
} from "@toolflow/shared";
import {
  MARKETPLACE_TAB,
  STARRED_TAB,
  USER_TOOLS_TAB
} from "./utils/constants";
import { useHandleUserEntities } from "../../../entities/hooks/useGetAllUserEntities";
import {
  MarketplaceFillIcon,
  MarketplaceIcon,
  StarFillIcon,
  StarIcon,
  UserFillIcon,
  UserIcon
} from "../../../../globalTheme/icons/icons";
import { useAllMarketplaceEntities } from "../../../entities/hooks/useMarketplaceEntities/useAllMarketplaceEntities";
import { useOnlyStarredEntities } from "../../../entities/hooks/useStarredEntities";

type TTabValues = 0 | 1;
let tabId: TTabValues = 0;

export type TToolTab = {
  value: TSearchToolsTabType;
  indexValue: number;
  label: string;
  getEntities: () => TMarketplaceUniversalEntity[];
  icon: JSX.Element;
  selectedIcon: JSX.Element;
  RemixIconRaw: React.ElementType;
};

export const iconSize = 20;

export const toolTabs: TToolTab[] = [
  {
    value: USER_TOOLS_TAB,
    indexValue: tabId++,
    label: "My tools",
    getEntities: useHandleUserEntities,
    icon: <UserIcon size={iconSize} />,
    selectedIcon: <UserFillIcon size={iconSize} />,
    RemixIconRaw: UserIcon
  },
  {
    value: MARKETPLACE_TAB,
    indexValue: tabId++,
    label: "Marketplace",
    getEntities: useAllMarketplaceEntities,
    icon: <MarketplaceIcon size={iconSize} />,
    selectedIcon: <MarketplaceFillIcon size={iconSize} />,
    RemixIconRaw: MarketplaceIcon
  },
  {
    indexValue: tabId++,
    value: STARRED_TAB,
    label: "Starred",
    getEntities: useOnlyStarredEntities,
    icon: <StarIcon size={iconSize} />,
    selectedIcon: <StarFillIcon size={iconSize} />,
    RemixIconRaw: StarIcon
  }
];
