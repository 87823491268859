import {
  AssetSourceType,
  AssetValueType,
  type TStructuredAsset
} from "@toolflow/shared";
import { v4 as uuidv4 } from "uuid";

export const getNewStructuredAsset = ({
  value,
  id,
  name,
  version = 1,
  toolId,
  sourceIdentifier
}: {
  value?: $TSFixMe;
  id?: string;
  name?: string;
  version?: number;
  toolId?: string;
  sourceIdentifier?: string;
}): TStructuredAsset => {
  return {
    label: name || `New structured asset`,
    name: name || `New structured asset`,
    source: toolId ? AssetSourceType.ToolOutput : AssetSourceType.Manual,
    value: value || {},
    valueType: AssetValueType.Structured,
    id: id || uuidv4(),
    sourceIdentifier,
    data: {
      version,
      toolId
    }
  };
};
