import { useSelector } from "react-redux";
import { useWebsocketContext } from "../../../../../../../../../ToolflowAPI/websocket/contexts/WebsocketContext";
import { useHasSelectedEntity } from "../../../../../../hooks/useHasSelectedEntity";
import { useCanRunAgent } from "../../../hooks/useCanRunAgent";
import { RootState } from "../../../../../../../../../stores/store";
import { useFilesFromFullScreenDropzone } from "../../../../../../../../files/fileUpload/FullScreenFileDropzone";
import useAnthropicTokenLimitLevel from "../../../ChatInputPaper/hooks/useAnthropicTokenLimitLevel";

const useSendChatDisabled = () => {
  const { isFetching, canRunAgent } = useCanRunAgent();
  const { uploadInProgress } = useFilesFromFullScreenDropzone();
  const { showReconnectButton } = useWebsocketContext();
  // if a tool or workflow is selected, disable the chat button
  const hasSelectedEntity = useHasSelectedEntity();
  const anthropicChatLimitLevel = useAnthropicTokenLimitLevel();

  const actionButtonDisabled = useSelector(
    (state: RootState) => state.chat.actionButtonDisabled
  );
  return (
    showReconnectButton ||
    hasSelectedEntity ||
    !canRunAgent ||
    isFetching ||
    actionButtonDisabled ||
    uploadInProgress ||
    anthropicChatLimitLevel === 2
  );
};

export default useSendChatDisabled;
