import React from "react";
import { SearchIcon } from "../../../../../../../globalTheme/icons/icons";
import useFindToolButton from "./hooks/useFindToolButton";
import TextButtonGray from "./textButtonGray/textButtonGray";
import {
  SearchEntitiesDialog,
  SearchEntitiesDialogType
} from "../../../../../../tools/components/searchEntitiesDialog/SearchEntitiesDialog";

function FindAgentButton({
  className = "",
  name = "Find agent",
  button
}: {
  className?: string;
  name?: string;
  button?: React.ReactElement<{
    onClick?: (e: React.MouseEvent) => void;
    disabled?: boolean;
  }>;
}) {
  const { selectTool } = useFindToolButton();

  return (
    <SearchEntitiesDialog
      onClickEntity={selectTool}
      title={name}
      type={SearchEntitiesDialogType.AGENTS}
      disabledEntities={{ tools: true, workflows: true }}
      button={
        button ? (
          button
        ) : (
          <TextButtonGray
            onClick={() => {}}
            className={className}
            text={name}
            Icon={SearchIcon}
          />
        )
      }
    />
  );
}

export default FindAgentButton;
