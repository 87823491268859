import { useWatch } from "react-hook-form";
import { WORKSPACE_CHAT_AGENT_ID_FIELD_LABEL } from "../../../../helpers/workspaceConstants";

const useGetCurrentChatAgentId = () => {
  // We updated from watch because watch needs to be subscribed to the form state to trigger rerenders
  // If you are having trouble with rerenders, try using useWatch instead of watch
  const agentId = useWatch({
    name: WORKSPACE_CHAT_AGENT_ID_FIELD_LABEL
  });
  return agentId;
};

export default useGetCurrentChatAgentId;
