import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { parsedClassNames } from "../../../../../../../utilities/functions/parsedClassNames";
import styles from "./chatInputPaper.module.css";
import useAnthropicTokenLimitLevel from "./hooks/useAnthropicTokenLimitLevel";

const cx = parsedClassNames.bind(styles);

enum TipLevel {
  Tip = "tip",
  Error = "error"
}

export default function ChatTips() {
  const anthropicChatLimitLevel = useAnthropicTokenLimitLevel();

  const [tip, setTip] = useState("");
  const [tipLevel, setTipLevel] = useState<TipLevel>(TipLevel.Tip);

  useEffect(() => {
    if (anthropicChatLimitLevel === 1) {
      setTip("Long chats cause you to reach your usage limit faster.");
      setTipLevel(TipLevel.Tip);
    } else if (anthropicChatLimitLevel === 2) {
      setTip(
        "Your message will exceed the limit. Try shortening your message or start a new chat."
      );
      setTipLevel(TipLevel.Error);
    } else {
      setTipLevel(TipLevel.Tip);
      setTip("");
    }
  }, [anthropicChatLimitLevel]);

  if (!tip) return null;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      className={cx(
        "border-radius-top-12px p-t-8px p-b-16px p-h-16px chat-tips-container",
        { "bg-dark-primary-color": tipLevel === TipLevel.Tip },
        { "bg-dark-error-color": tipLevel === TipLevel.Error }
      )}
    >
      <Typography variant="caption">
        <span className="semi-bold">Tip: </span>
        {tip}
      </Typography>
    </Stack>
  );
}
