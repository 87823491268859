import { ElementType } from "react";
import { CustomEdge, TBlock } from "../models";
import { CustomToolOutputField } from "../outputs";
import { DecimalString } from "../primitives";
import {
  $TSAllowedAny,
  OriginalToolState,
  ToolOutputOption,
  ValidatedInput
} from "../types";
import { BlockDataTypes, BlockDataTypesWithDefault } from "./blocks";
import { BlockDataBase, BlockMessageType } from "./blocksBaseTypes";

export type DraggableItem = Omit<
  TBlock,
  "id" | "position" | "data" | "parentNode"
> & {
  data: BlockDataTypes;
};

export enum OutputConfigOptions {
  List = "list-output"
}

export type OutputConfigGetterFunction = (args: {
  id: string;
  data: BlockDataBase;
  toolOutputField: CustomToolOutputField;
  currentState: OriginalToolState;
}) => OutputConfigOptions[];

export interface IValidateProps<T extends BlockDataTypesWithDefault> {
  data: T;
  edges: CustomEdge[];
  id?: string;
}

export type ValidatorFunction<T extends BlockDataTypesWithDefault> = (
  props: IValidateProps<T>
) => BlockMessageType | undefined;

export type BlockInputUpdaterFunction = (
  block: TBlock,
  oldName: string,
  newName?: string,
  deleteBrackets?: boolean
) => TBlock;

export type BlockInputUpdater = Record<
  string,
  Record<DecimalString, BlockInputUpdaterFunction>
>;

export type GetNestedFieldsFn = (
  data: $TSAllowedAny,
  blocks: TBlock[]
) => ValidatedInput[];

export type GetOutputOptionsFn = (data: $TSAllowedAny) => ToolOutputOption[];

export type FnsRecord<T> = Record<string, Record<DecimalString, T>>;
export type AvailableFieldFnRecord = FnsRecord<GetNestedFieldsFn>;
export type OutputOptionFnsRecord = FnsRecord<GetOutputOptionsFn>;
export type BlockFunctionRecords = FnsRecord<BlockConfigFunctions>;

export interface BlockConfigFunctions {
  validate?: ValidatorFunction<BlockDataTypes>;
  updateInput?: BlockInputUpdaterFunction;
  getOutputConfigOptions?: OutputConfigGetterFunction;
  getNestedFields?: GetNestedFieldsFn;
  getOutputOptions?: GetOutputOptionsFn;
}

export interface BlockConfig extends BlockConfigFunctions {
  icon: ElementType;
  drawerComponent: ElementType;
  expandedComponent?: ElementType;
  draggableItem: DraggableItem;
  blockPaperLabel: string;
}

export type VersionedBlockConfigValue = Omit<BlockConfig, "draggableItem"> & {
  draggableItem: DraggableItem;
};

export type VersionedBlockConfig = {
  [version: string]: VersionedBlockConfigValue;
};

export const DEFAULT_VERSION_VALUE: DecimalString = "1.0";
export const VERSION_2_VALUE: DecimalString = "2.0";
