import type { JSONContent } from "@tiptap/core";
import {
  createContext,
  ReactNode,
  useContext,
  useState,
  type Dispatch,
  type SetStateAction
} from "react";

interface ChatText {
  userTextMessage: JSONContent;
  setUserTextMessage: Dispatch<SetStateAction<JSONContent>>;
}

const ChatTextContext = createContext<ChatText | null>(null);

export function ChatTextProvider({ children }: { children: ReactNode }) {
  const [userTextMessage, setUserTextMessage] = useState<JSONContent>({});

  return (
    <ChatTextContext.Provider
      value={{
        userTextMessage,
        setUserTextMessage
      }}
    >
      {children}
    </ChatTextContext.Provider>
  );
}

export default function useChatText() {
  const values = useContext(ChatTextContext);
  if (!values) {
    throw new Error("Cannot use ChatText outside of provider.");
  }
  return values;
}
