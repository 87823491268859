import { isHandledChatStackError, type JSONValue } from "@toolflow/shared";
import { Fragment } from "react";
import {
  isArrayOfObjects,
  isArrayOfSingleValues,
  isObjectType,
  isSingleLineValue
} from "../helpers/structuredOutputHelpers";
import { StructuredListOfObjects } from "./StructuredListOfObjects";
import { StructuredListOfSingleValues } from "./StructuredListOfSingleValues";
import { StructuredOutputAccordion } from "./StructuredOutputAccordion";
import { StructuredSingleValue } from "./StructuredSingleValue";

export function StructuredObject({
  name,
  value,
  transparentBg
}: {
  name: string;
  value: JSONValue;
  transparentBg?: boolean;
}) {
  return (
    <StructuredOutputAccordion name={name} transparentBg={transparentBg}>
      {Object.entries(value).map(([key, val], index) => {
        return (
          <Fragment key={index}>
            {isHandledChatStackError(value) ? (
              <StructuredSingleValue name={key} value={value.data.message} />
            ) : isSingleLineValue(val) ? (
              <StructuredSingleValue name={key} value={val} />
            ) : isArrayOfSingleValues(val) ? (
              <StructuredListOfSingleValues name={key} value={val} />
            ) : isArrayOfObjects(val) ? (
              <StructuredListOfObjects name={key} value={val} />
            ) : isObjectType(val) ? (
              <StructuredObject name={key} value={val} />
            ) : (
              <></>
            )}
          </Fragment>
        );
      })}
    </StructuredOutputAccordion>
  );
}
