import { useEffect, useState } from "react";
import useGetAgentModelType from "../../SendThreadTextField/hooks/useGetAgentModelType";
import useChatMessages from "../../hooks/useChatMessages";
import useChatText from "./useChatText";
import useGenerateTextFromJSON from "../../../../../../../../utilities/components/textFields/workspace/hooks/useGenerateTextFromJSON";
import { ChatLLMType } from "@toolflow/shared";
import { isEmpty } from "lodash";

const ANTHROPIC_MAX_CHAT_TOKEN_LIMIT = 130000;
const ANTHROPIC_NEAR_CHAT_TOKEN_LIMIT = 100000;

/**
 * Checks message tokens for anthropic chat and determines the level of limit exceeded
 *
 * 0 means not reached
 *
 * 1 means near limit
 *
 * 2 means limit reached or exceeded
 * @returns 0 | 1 | 2
 */
export default function useAnthropicTokenLimitLevel() {
  const [level, setLevel] = useState<0 | 1 | 2>(0);

  const modelType = useGetAgentModelType();
  const { messages } = useChatMessages();
  const { userTextMessage } = useChatText();
  const generateTextFromJson = useGenerateTextFromJSON();

  useEffect(() => {
    let currentMessage = "";
    if (!isEmpty(userTextMessage)) {
      currentMessage = generateTextFromJson(userTextMessage);
    }
    const messagesText = messages.map((m) => m.message).join("");
    // For Anthropic a token roughly represents 3.5 characters.
    const estimatedTotalTokens =
      `${messagesText}${currentMessage}`.length / 3.5;

    if (
      modelType === ChatLLMType.Anthropic &&
      estimatedTotalTokens >= ANTHROPIC_MAX_CHAT_TOKEN_LIMIT
    ) {
      setLevel(2);
    } else if (
      modelType === ChatLLMType.Anthropic &&
      estimatedTotalTokens >= ANTHROPIC_NEAR_CHAT_TOKEN_LIMIT
    ) {
      setLevel(1);
    } else {
      setLevel(0);
    }
  }, [messages, userTextMessage]);

  return level;
}
