import { integrationsList } from "./useIntegrationMetadata";
import IntegrationCard from "./components/IntegrationCard/UserIntegrationCard";

const UserIntegrationSettings = () => {
  return (
    <div>
      <div className="flex flex-wrap">
        {integrationsList.map((integration) => (
          <IntegrationCard
            key={integration.type}
            integration={integration}
            className="m-h-8px m-b-16px"
          />
        ))}
      </div>
    </div>
  );
};

export default UserIntegrationSettings;
