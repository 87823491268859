import React from "react";
import TogglePrimaryHeaderTextFieldBase from "../../../../../utilities/components/textFields/TogglePrimaryHeaderTextFieldBase";
import UpdateStepNameTextField from "./UpdateStepNameTextField";
import { useFormContext } from "react-hook-form";
import useGetWorkflowStepNameFieldById from "./hooks/useGetWorkflowStepNameFieldById";
import type { TWorkflowBuilderForm } from "@toolflow/shared";
import ErrorContainer from "../../../../../utilities/components/errors/ErrorContainer";

const ToggleStepNameTextField = ({
  stepId,
  disabled
}: {
  stepId: string;
  disabled?: boolean;
}) => {
  const { watch } = useFormContext<TWorkflowBuilderForm>();
  const fieldLabel = useGetWorkflowStepNameFieldById(stepId);
  const stepName = watch(fieldLabel);

  return (
    <TogglePrimaryHeaderTextFieldBase
      value={stepName}
      disabled={disabled}
      errorMessage={<ErrorContainer fieldName={stepName} withForm />}
    >
      <UpdateStepNameTextField fieldLabel={fieldLabel} />
    </TogglePrimaryHeaderTextFieldBase>
  );
};

export default ToggleStepNameTextField;
