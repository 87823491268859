import { MenuItem, Select, Tooltip, Typography } from "@mui/material";
import {
  CustomToolInputFieldTypesEnum,
  DynamicFieldType,
  type CustomToolInputField,
  type DynamicListFieldConfig,
  type SelectOption,
  type ValidatedInput
} from "@toolflow/shared";
import { isString } from "lodash";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { ArrowDownSmallIcon } from "../../../../../../../../globalTheme/icons/icons";
import { parsedClassNames } from "../../../../../../../../utilities/functions/parsedClassNames";
import { CREATE_USER_INPUT_LABEL } from "../../../../../../../tools/components/editorToolCard/inputs/helpers/constants";
import AddInputDialog from "../../../../addInput/AddInputDialog";
import useToolInputFieldsAndAvailableFields from "../../../../useToolInputFieldsAndAvailableFields";
import useUpdateField from "../../../../useUpdateField";
import styles from "./dynamicFieldWrapper.module.css";

const cx = parsedClassNames.bind(styles);

export default function DynamicFieldWrapper({
  id,
  label,
  subtitle,
  fieldValue,
  type = DynamicFieldType.Dynamic,
  configs,
  children,
  addInputType,
  filterOptions,
  onTypeChangeCallback
}: {
  id: string;
  label: string;
  subtitle?: string;
  fieldValue: string;
  type: DynamicFieldType;
  configs: DynamicListFieldConfig;
  children: ReactNode;
  addInputType?: CustomToolInputFieldTypesEnum;
  onTypeChangeCallback?(type: DynamicFieldType): void;
  filterOptions?(options: ValidatedInput[]): ValidatedInput[];
}) {
  const { forceDynamic, options, allowedFields } = configs;
  const { fieldKey, typeKey } = configs;
  const availableFields = useToolInputFieldsAndAvailableFields(id);
  const updateField = useUpdateField(id);

  const selectOptions: SelectOption[] = useMemo(() => {
    const fields = filterOptions?.(availableFields) || availableFields;
    return fields.map((field) => {
      return {
        label: field.config?.label || field.name,
        value: field.config?.label || field.name
      };
    });
  }, [filterOptions, availableFields]);

  useEffect(() => {
    if (forceDynamic && type !== DynamicFieldType.Dynamic) {
      updateField(DynamicFieldType.Dynamic, typeKey);
      updateField("", fieldKey);
    }
  }, [forceDynamic]);

  const [openInputModal, setOpenInputModal] = useState(false);
  const updateFromAdd = (value: CustomToolInputField) => {
    if (value.type === addInputType) {
      updateField(value.name, fieldKey);
    }
  };

  return (
    <div className={cx("p-16px border-radius-8px", "container")}>
      <Typography variant="subtitle1" fontWeight="500">
        {label}
      </Typography>
      {subtitle && (
        <Typography variant="caption" color="text.secondary">
          {subtitle}
        </Typography>
      )}

      <Select
        value={type}
        size="small"
        fullWidth
        onChange={(event) => {
          if (!forceDynamic) {
            updateField(event.target.value, typeKey);
            onTypeChangeCallback?.(event.target.value as DynamicFieldType);
          }
        }}
        className={cx("select-field")}
        variant="standard"
        IconComponent={() => (
          <ArrowDownSmallIcon className={cx("select-field-icon")} />
        )}
      >
        <MenuItem
          className={cx("select-field-item")}
          value={DynamicFieldType.Dynamic}
          key={DynamicFieldType.Dynamic}
        >
          <Tooltip
            title="Dynamic value is piped through other blocks or user inputs."
            placement="top"
          >
            <div>🎛️ Dynamic value</div>
          </Tooltip>
        </MenuItem>
        <MenuItem
          className={cx("select-field-item")}
          value={DynamicFieldType.Preset}
          key={DynamicFieldType.Preset}
        >
          <Tooltip
            title="Set a preset value that will be used for this tool."
            placement="bottom"
          >
            <div>🔒 Pre-set value</div>
          </Tooltip>
        </MenuItem>
      </Select>

      {type === DynamicFieldType.Dynamic ? (
        <Select
          value={fieldValue}
          size="small"
          fullWidth
          onChange={(event) => updateField(event.target.value, fieldKey)}
          className="nowheel nodrag nopan m-t-16px bg-color-white"
        >
          {addInputType && (
            <MenuItem
              value={CREATE_USER_INPUT_LABEL}
              onClick={() => setOpenInputModal(true)}
            >
              {CREATE_USER_INPUT_LABEL}
            </MenuItem>
          )}
          {selectOptions.map((option) => {
            if (isString(option)) {
              return (
                <MenuItem value={option} key={option} className="capitalize">
                  {option}
                </MenuItem>
              );
            } else {
              return (
                <MenuItem
                  value={option.value}
                  key={option.value}
                  className="capitalize"
                >
                  {option.label}
                </MenuItem>
              );
            }
          })}
        </Select>
      ) : (
        children
      )}

      {addInputType && (
        <AddInputDialog
          allowedFields={allowedFields}
          openModal={openInputModal}
          setOpenModal={setOpenInputModal}
          callback={updateFromAdd}
          initialType={addInputType}
          initialOptions={
            addInputType === CustomToolInputFieldTypesEnum.SELECT
              ? options?.map((option) =>
                  isString(option) ? option : option.value
                )
              : undefined
          }
        />
      )}
    </div>
  );
}
