import { isString } from "lodash";
import { useFormContext } from "react-hook-form";
import FileUploadButtonInner from "../../files/fileUpload/FileUploadButtonInner";
import { SNIPPET_VALUE_FIELD_LABEL } from "./snippetFields";
import { FIVE_MEGA_BYTES_IN_BYTES } from "@toolflow/shared";

export default function SnippetFileField() {
  const { watch, setValue } = useFormContext();
  const filedValues = watch();
  const value = filedValues[SNIPPET_VALUE_FIELD_LABEL];

  if (isString(value) && value.length) {
    return null;
  }

  return (
    <>
      <FileUploadButtonInner
        showUploadedFiles
        currentFile={filedValues[SNIPPET_VALUE_FIELD_LABEL]}
        setCurrentFile={(file) => setValue(SNIPPET_VALUE_FIELD_LABEL, file)}
        helperText="txt/doc/docx, max 5 MB"
        maxSize={FIVE_MEGA_BYTES_IN_BYTES}
        accept={{
          "text/plain": [".txt"],
          "application/msword": [".doc"],
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            [".docx"]
        }}
      />
    </>
  );
}
