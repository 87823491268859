import { Stack } from "@mui/material";
import {
  BlockNode,
  CopyableField,
  type YoutubeTranscriptBlockData,
  type YoutubeTranscriptBlockProps
} from "@toolflow/shared";
import DrawerBottom from "../../builderDrawer/components/drawerBottom/DrawerBottom";
import TestToolRun from "../../common/TestToolRun";
import TestOutput from "../components/testWrapper/TestOutput";
import useTest from "../hooks/useTest";
import useTestInputFromFieldsByType from "../hooks/useTestInputFromFieldsByType";
import useYoutubeTranscriptFieldsConfig from "./useYoutubeTranscriptFieldsConfig";
import TestFields from "./TestFields";

function TestYoutubeTranscript({
  data,
  id
}: Omit<YoutubeTranscriptBlockProps, "selected">) {
  const fieldsByType = useYoutubeTranscriptFieldsConfig();
  const { fields, userInput, setUserInput } = useTestInputFromFieldsByType(
    fieldsByType,
    data,
    [data.settings]
  );

  const { action, text, endIcon, showUpgrade, output, label, loading } =
    useTest<YoutubeTranscriptBlockData>({
      data,
      id,
      blockType: BlockNode.YoutubeTranscriptNode,
      fields,
      userInput
    });

  return (
    <DrawerBottom
      mainContent={
        <Stack spacing={2} className="m-t-32px">
          <TestFields fields={fields} uI={userInput} setUI={setUserInput} />
          <TestOutput
            output={output}
            label={label}
            type={CopyableField.Structured}
          />
        </Stack>
      }
      rightSlot={
        <TestToolRun
          action={action}
          text={text}
          endIcon={endIcon}
          showUpgrade={showUpgrade}
          loading={loading}
        />
      }
    />
  );
}

export default TestYoutubeTranscript;
